import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileDropzone, Input, Modal, PhoneInput, phonemasks } from '@forma/forma-ui-kit';
import getValidationSchema from 'data/validationSchema';
import SelectOwnerModal from '../SelectOwnerModal';

import styles from './send-sign-modal.module.css';

interface SendSignModalProps {
  open: boolean,
  onClose: () => void,
  onSubmit: (ownerId: string, name: string, signer: { name: string, phone: string }, files: File[]) => void,
  isLoading?: boolean,
  name: string
}

interface FormValues {
  documentName: string,
  fullName: string,
  phone: string
}

const SendSignModal: FC<SendSignModalProps> = ({ open, onClose, onSubmit, isLoading, name }) => {
  const { t, i18n } = useTranslation();
  const [ selectedOwnerId, setSelectedOwnerId ] = useState<string|null>(null);
  const [ files, setFiles ] = useState<File[]>([]);

  const { register, control, handleSubmit, formState: { isValid, errors } } = useForm<FormValues>({
    resolver: yupResolver(getValidationSchema(['documentName', 'fullName', 'phone'])),
    defaultValues: { documentName: name }
  });

  const handleSubmitForm = (values: FormValues) => {
    if (!selectedOwnerId) return;

    onSubmit(
      selectedOwnerId,
      values.documentName,
      {
        name: values.fullName,
        phone: values.phone
      },
      files
    );
  };

  if (!selectedOwnerId) return (
    <SelectOwnerModal
      open={open && !selectedOwnerId}
      onClose={onClose}
      onSubmit={setSelectedOwnerId}
      isSign
    />
  );

  return (
    <Modal
      title={t('send_to_counteragent_sign')}
      open={open}
      onClose={onClose}
      size="large"
      buttons={[
        {
          type: 'submit',
          children: t('send_to_sign'),
          className: styles.save,
          isLoading: isLoading,
          disabled: !selectedOwnerId || !isValid,
          form: 'send_sign_form',
          closing: false
        }
      ]}
    >
      <div className={styles.description}>{t('send_to_counteragent_sign_description')}</div>
      <div className={styles.content}>
        <form id="send_sign_form" className={styles.form} onSubmit={handleSubmit(handleSubmitForm)}>
          <Controller
            control={control}
            name="documentName"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                containerClass={styles.field}
                placeholder={t('enter_document_name')}
                label={t('document_name')}
                viewStyle="secondary"
                showClearButton
                maxLength={255}
                error={!!errors?.documentName?.message}
                errorMessage={errors?.documentName?.message && t('errors.' + errors.documentName.message)}
                {...rest}
                {...register}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="fullName"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                containerClass={styles.field}
                placeholder={t('placeholders.name')}
                label={t('fullname_short')}
                viewStyle="secondary"
                showClearButton
                maxLength={255}
                error={!!errors?.fullName?.message}
                errorMessage={errors?.fullName?.message && t('errors.' + errors.fullName.message)}
                {...rest}
                {...register}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field: { value = '', ...rest } }) => (
              <PhoneInput
                masks={phonemasks}
                defaultMaskCode={(i18n.resolvedLanguage === 'en' || !i18n.resolvedLanguage) ? 'US' : i18n.resolvedLanguage.toUpperCase()}
                containerClass={styles.field}
                placeholder={t('placeholders.phone')}
                label={t('fields.phone')}
                viewStyle="secondary"
                showClearButton
                error={!!errors?.phone?.message}
                errorMessage={errors?.phone?.message && t('errors.' + errors.phone.message)}
                autoComplete="phone"
                {...rest}
                {...register}
                value={value}
              />
            )}
          />
        </form>
        <FileDropzone
          placeholder={<Trans i18nKey="send_to_counteragent_sign_files" />}
          accept={{
            'image/jpeg': [], 'image/png': [], 'image/bmp': [], 'image/gif': [], 'image/tiff': [], 'image/webp': []
          }}
          previews={{ columns: 3 }}
          maxFiles={10}
          className={styles.dropzone}
          onChange={setFiles}
        />
      </div>
    </Modal>
  );
};

export default SendSignModal;
