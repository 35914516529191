import { FC, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import PaymentModal from 'views/tariffs/PaymentModal';
import { getCookie } from 'helpers/cookies';
import { TRIAL_ID } from 'data/constants';
import { IPromocode, ITariff, ITariffItem } from 'interfaces/tariffs.interface';
import { IPaymentSubscription } from 'interfaces/billing.interface';

import TariffsList from '../TariffsList';
import TariffSubscription, { TariffSubscriptionSkelet } from './TariffSubscription';
import TariffTotal from './TariffTotal';
import PromoInput from './PromoInput';
import SavedPaymentMethods from '../SavedPaymentMethods';

import styles from './tariff-select.module.css';

interface TariffSelectProps {
  current?: ITariff,
  tariffs?: ITariffItem[],
  subscription?: IPaymentSubscription,
  promo: {
    info: IPromocode|null,
    isError: boolean,
    onSubmit: (code: string) => void,
    onCancel: () => void
  },
  paymentOpen?: boolean,
  defaultMethod?: string
}

const TariffSelect: FC<TariffSelectProps> = ({ tariffs, current, subscription, promo, paymentOpen = false, defaultMethod }) => {
  const { t } = useTranslation();
  const [ mode, setMode ] = useState<'view'|'change'|'await'>('view');
  const [ isShowPromoTariff, setShowPromoTariff ] = useState<boolean>(false);
  const [ isPaymentOpen, setPaymentOpen ] = useState<boolean>(paymentOpen);
  const [ selectedTariff, setSelectedTariff ] = useState<string|null>(null);
  const [ selectedDuration, setSelectedDuration ] = useState<number>(0);
  const [ selectedMembers, setMembers ] = useState<number>(0);

  const tariffsCanChoose = useMemo(() => (
    tariffs?.filter(({ patterns }) => patterns.findIndex(({ canChoose }) => !!canChoose) >= 0)
  ), [tariffs]);

  useEffect(() => {
    if (!tariffs || !current) return;

    let tariff: ITariffItem|undefined = tariffs.find(({ patterns }) => patterns.findIndex(({ canChoose }) => !!canChoose) >= 0);
    let tariffId = tariff?.id || '';
    let duration = tariff?.patterns.find(({ canChoose }) => canChoose)?.duration || 0;
    let members = 0;

    if (current.tariff.id === TRIAL_ID) {
      setMode('change');
    } else {
      if (current.isActive) {
        if (current.isPaid) setMode('view');
        else setMode('await');
      } else {
        setMode('change');
      }
    }

    let promoTariff = null;
    let promoDuration = null;
    if (promo?.info?.type === 'tariff' && promo?.info?.patternId) {
      tariffs.forEach(({ id, patterns }) => {
        const pattern = patterns.find(({ id }) => id === promo?.info?.patternId);
        if (pattern) {
          promoTariff = id;
          promoDuration = pattern.duration;
        }
      });
    }

    if (promoTariff && promoDuration) {
      tariffId = promoTariff;
      duration = promoDuration;
      // members = current.usersCount - promoTariff.usersLimit;
    } else if (current.tariff.id === TRIAL_ID) {
      const regTariff = getCookie('tariff');
      const cookieTariff: { tariff: string, duration: number, users: number } = regTariff && JSON.parse(decodeURIComponent(regTariff));
      if (cookieTariff) {
        if (cookieTariff.tariff && tariffs.find(({ id }) => id === cookieTariff.tariff)) {
          tariffId = cookieTariff.tariff;
          if (cookieTariff.duration) duration = cookieTariff.duration;
        }
        if (cookieTariff.users) members = cookieTariff.users;
      }
    } else {
      if (current.tariff.id !== TRIAL_ID) {
        tariffId = current.tariff.id;
        duration = current.duration;
        tariff = tariffs.find(({ id }) => id === current.tariff.id);
        if (tariff) members = current.usersCount - tariff.usersLimit;
      }
    }

    setSelectedTariff(tariffId);
    setSelectedDuration(duration);
    setMembers(members);
  }, [tariffs, current, promo]);

  useEffect(() => {
    if (promo.info?.type === 'tariff') setShowPromoTariff(true);
  }, [promo]);

  const selectedTariffItem = tariffs?.find(({ id }) => id === selectedTariff);
  const selectedPattern = selectedTariffItem?.patterns.find(({ duration }) => duration === selectedDuration);

  const handleSelectTariff = (id: string) => {
    setSelectedTariff(id);
    const tariff = tariffs?.find(tariff => id === tariff.id);
    if (tariff?.patterns.findIndex(({ duration }) => duration === selectedDuration) === -1) {
      const newDuration = tariff.patterns.find(({ canChoose }) => canChoose)?.duration;
      if (newDuration) setSelectedDuration(newDuration);
    }
  };

  const handleClickCancelChange = () => {
    if (current?.isPaid) setMode('view');
    else setMode('await');

    if (current) {
      setSelectedTariff(current.tariff.id);
      setSelectedDuration(current.duration);
      const tariff = tariffs?.find(({ id }) => id === current.tariff.id);
      if (tariff) setMembers(current.usersCount - tariff.usersLimit);
    }
  };

  const dateTo = current && new Date(current.dtTo);

  /*
  // можно добавлять пользователей и ((текущий не активен и не оплачен) или новый.польз > текущий.польз)
  const canChangeUsers = current && selectedTariffItem
    && selectedTariffItem.canAddUsers
    && (!(current.isActive && current.isPaid) || selectedTariffItem.usersLimit + selectedMembers > current.usersCount);

  // выбран другой тариф и ((текущий не активен и не оплачен) или новый.цена > текущий.оплаченная_цена)
  const canPlaceOrder = !isTariffAndPatternEqual && selectedPattern && current
    && (!(current.isActive && current.isPaid) || selectedPattern?.price > current.amountToPay);
  */

  // const isTariffEqual = current && selectedTariffItem && selectedPattern
  //   && selectedTariffItem.id === current.tariff.id
  //   && selectedPattern.id === current.patternId
  //   && (!selectedTariffItem.canAddUsers || selectedTariffItem.usersLimit + selectedMembers === current.usersCount);

  const promoTariff = promo.info?.type === 'tariff' ? tariffs?.reduce((acc: ITariffItem[], current: ITariffItem) => {
    const pattern = current.patterns.find(({ id }) => id === promo.info?.patternId);
    if (pattern) return [ ...acc, { ...current, isPromo: true, patterns: [ { ...pattern, isPromo: true } ] } ];
    return acc;
  }, []) : [];

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <div className={classNames(styles.status, (dateTo && dateTo < new Date()) && styles.error)}>
          {(current && dateTo) && (
            current.tariff.id === 'tariff_trial' ? (
              dateTo > new Date() ? (
                t('subscription.trial_period_available_unitl', { date: format(dateTo, 'dd.MM.yyyy') })
              ) : (
                t('subscription.trial_period_expired', { date: format(dateTo, 'dd.MM.yyyy') })
              )
            ) : (
              !subscription?.id ? (
                dateTo > new Date() ? (
                  t('subscription.your_tariff_availabe_until', { name: current.tariff.name, date: format(dateTo, 'dd.MM.yyyy') })
                ) : (
                  t('subscription.your_tariff_expired', { name: current.tariff.name, date: format(dateTo, 'dd.MM.yyyy') })
                )
              ) : (
                dateTo > new Date() ? (
                  t('subscription.your_subscription_tariff_availabe_until', { name: current.tariff.name, date: format(dateTo, 'dd.MM.yyyy') })
                ) : (
                  t('subscription.your_subscription_tariff_expired', { name: current.tariff.name, date: format(dateTo, 'dd.MM.yyyy') })
                )
              )
            )
          )}
        </div>
        <div className={styles.headButtons}>
          <SavedPaymentMethods tariff={current} subscription={subscription} />
          <Button
            as={Link}
            viewStyle="secondary"
            to="/billings"
            shadow
          >
            {t('subscription.billings_and_acts')}
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.tariffs}>
          <TariffsList
            mode={mode}
            current={current}
            items={(isShowPromoTariff && promoTariff?.length) ? promoTariff : tariffsCanChoose}
            selectedTariff={selectedTariff}
            onSelectTariff={handleSelectTariff}
            selectedDuration={selectedDuration}
            selectedMembers={selectedMembers}
            onChangeMembers={setMembers}
            hasPromoTariff={!!promoTariff?.length}
            isShowPromo={isShowPromoTariff}
            onTogglePromo={setShowPromoTariff}
            promocode={promo.info}
          />
        </div>
        <div className={styles.side}>
          {tariffs ? (
            <TariffSubscription
              mode={mode}
              current={current}
              tariffName={selectedTariffItem?.name}
              patterns={(isShowPromoTariff && promoTariff?.length) ? promoTariff[0].patterns : selectedTariffItem?.patterns}
              selectedDuration={selectedDuration}
              onSelectDuration={setSelectedDuration}
              promocode={promo.info}
            />
          ) : (
            <TariffSubscriptionSkelet />
          )}

          <div className={styles.promo}>
            <PromoInput {...promo} showButtons={mode === 'change'} />
          </div>

          <TariffTotal
            tariff={selectedTariffItem}
            members={selectedMembers}
            selectedDuration={selectedDuration}
          />

          <div className={styles.buttons}>
            {mode === 'view' && (
              <>
                <Button
                  className={styles.button}
                  viewStyle="secondary"
                  onClick={() => setMode('change')}
                  shadow
                >
                  {t('subscription.change_tariff')}
                </Button>
                {/* {subscription && !subscription?.id && (
                  <LoadingButton
                    className={styles.button}
                    viewStyle="primary"
                    onClick={() => setPaymentOpen(true)}
                    shadow
                  >
                    {t('subscription.extend_tariff')}
                  </LoadingButton>
                )} */}
              </>
            )}
            {mode === 'change' && (
              <>
                {current?.tariff.id !== TRIAL_ID && (
                  <Button
                    className={styles.button}
                    viewStyle="secondary"
                    onClick={handleClickCancelChange}
                    shadow
                  >
                    {t('cancel')}
                  </Button>
                )}
                <Button
                  className={styles.button}
                  viewStyle="primary"
                  onClick={() => setPaymentOpen(true)}
                  // disabled={isTariffEqual}
                  shadow
                >
                  {t('subscription.place_order')}
                </Button>
              </>
            )}
            {mode === 'await' && (
              <>
                <Button
                  className={styles.button}
                  viewStyle="secondary"
                  onClick={() => setMode('change')}
                  shadow
                >
                  {t('subscription.change_tariff')}
                </Button>
                <Button
                  className={styles.button}
                  viewStyle="primary"
                  onClick={() => setPaymentOpen(true)}
                  shadow
                >
                  {t('subscription.pay')}
                </Button>
              </>
            )}
            {(current && selectedTariffItem && selectedPattern) && (
              <PaymentModal
                open={isPaymentOpen}
                onClose={() => setPaymentOpen(false)}
                tariff={current}
                selectedTariff={selectedTariffItem}
                selectedPattern={selectedPattern}
                members={selectedMembers}
                subscription={subscription}
                defaultMethod={defaultMethod}
                mode={mode}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TariffSelect;