import { FC, ReactElement, ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FileWithPath } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { FileDropzone, Input, Modal, ModalButtonProps } from '@forma/forma-ui-kit';
import CreateTemplateButton from './CreateTemplateButton';

import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import { useImportTemplateMutation } from 'store/templates/templatesApi';
// import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { useCreateTemplateMutation } from 'store/templates/templatesApi';
import { useAppDispatch } from 'store/hooks';

import styles from './create-template-modal.module.css';
import { useSendTcRequestMutation } from 'store/user/userApi';
import { addNotification } from 'store/notifications/notificationsSlice';

type TCreateMode = 'select'|'create'|'upload'|'request'|'requestSuccess';

interface CreateTemplateProps {
  open?: boolean,
  onClose?: (open: false) => void,
  defaultMode?: TCreateMode,
  control?: ReactElement,
  parentid?: string|null,
  onCreate?: IFoldersEditingProps['onCreate'],
  // showOnboarding?: boolean
}

const CreateTemplateModal: FC<CreateTemplateProps> = ({ open, onClose, defaultMode = null, control, parentid, onCreate }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ mode, setMode ] = useState<TCreateMode|null>(defaultMode);
  const [ name, setName ] = useState<string>('');
  const [ uploadingFiles, setUploadingFiles ] = useState<File[]>([]);

  // const { data: tariff } = useGetCurrentTariffQuery();
  const [ createTemplateMutation ] = useCreateTemplateMutation();
  const [ importTemplate, { isLoading: isImportLoading } ] = useImportTemplateMutation();
  const [ sendRequest, { isLoading: isRequestLoading } ] = useSendTcRequestMutation();

  const createTemplate = (data: { name: string, folderid?: string|null }) => createTemplateMutation(data).then(response => {
    if (!('data' in response)) return null;
    return { id: response.data.id, name: response.data.name, translatedName: response.data.translatedName };
  });

  const handleCreateTemplate = async () => {
    const result = onCreate ? await onCreate({ name, type: 'template', parentid }) : await createTemplate({ name, folderid: parentid });
    if (result) navigate(`/editor/${result.translatedName}`);
  };

  const handleClickImport = async () => {
    if (!uploadingFiles.length) return;

    const formData = new FormData();
    formData.append('file', uploadingFiles[0]);
    formData.append('name', name);
    if (parentid) formData.append('folderid', parentid);

    const result = await importTemplate(formData);
    if ('data' in result) navigate(`/editor/${result.data.translatedName}`, { state: { imported: true } });
  };

  const handleClickRequest = async () => {
    for await (const file of uploadingFiles) {
      const formData = new FormData();
      formData.append('file', file);
      await sendRequest(formData)
        .catch(() => {
          dispatch(addNotification({ content: t('errors.file_not_send', { name: file.name }), type: 'ERROR' }));
        });
    }

    setMode('requestSuccess');
  };

  const handleAddFile = async (files: FileWithPath[]) => {
    if (!files.length) return;
    setUploadingFiles(files);
    const dotIndex = files[0].name.lastIndexOf('.');
    setName(files[0].name.slice(0, dotIndex));
  };

  const handleClickBack = () => {
    setMode(prev => {
      if (!prev) return null;
      if (['create', 'upload', 'request'].includes(prev)) return 'select';
      return null;
    });
  };

  const modalButtons: ModalButtonProps[] = [];
  if (mode && mode !== 'requestSuccess') modalButtons.push({
    viewStyle: 'tertiary',
    children: t('go_back'),
    onClick: handleClickBack,
    closing: false
  });
  if (mode === 'upload') modalButtons.push({
    viewStyle: 'primary',
    onClick: handleClickImport,
    disabled: !uploadingFiles.length,
    isLoading: isImportLoading,
    children: t('go_to_editor'),
    closing: false
  });
  if (mode === 'create') modalButtons.push({
    viewStyle: 'primary',
    onClick: handleCreateTemplate,
    disabled: name.length < 3,
    isLoading: isImportLoading,
    children: t('go_to_editor'),
    closing: false
  });
  if (mode === 'request') modalButtons.push({
    viewStyle: 'primary',
    onClick: handleClickRequest,
    disabled: !uploadingFiles.length,
    isLoading: isRequestLoading,
    children: t('send_templates_to_setting'),
    className: styles.requestButton,
    closing: false
  });
  if (mode === 'requestSuccess') modalButtons.push({
    viewStyle: 'primary',
    className: styles.successButton,
    children: t('fine'),
  });

  let title: ReactNode = t('how_you_want_start');
  if (mode === 'upload') title = t('upload_template_in_doc_format');
  if (mode === 'create') title = t('create_in_editor');
  if (mode === 'request') title = <Trans i18nKey={t('setting_templates_for_me')} components={{ accent: <span className={styles.accent} /> }} />;
  if (mode === 'requestSuccess') title = null;

  return (
    <Modal
      // title={t('creating_template')}
      title={title}
      open={open}
      onClose={() => {
        setMode(defaultMode);
        setUploadingFiles([]);
        if (onClose) onClose(false);
      }}
      maxWidth="730px"
      width="100%"
      containerClassName={styles.modal}
      buttonsClassName={styles.modalButtons}
      control={control}
      buttons={modalButtons.length ? modalButtons : undefined}
    >
      <div className={styles.modalContent}>
        {/* <input type="hidden" autoFocus /> */}

        {!mode && (
          <>
            <CreateTemplateButton
              name={t('create_template_from_scratch')}
              description={t('upload_template_in_doc_or_create_in_editor')}
              icon="/icons/explorer/file_i.svg"
              onClick={() => setMode('select')}
            />
            <CreateTemplateButton
              label={t('this_method_choosen_users')}
              name={t('select_from_many_templates')}
              description={t('select_from_many_templates_description')}
              icon="/icons/explorer/file_forma.svg"
              onClick={() => navigate('/static-templates')}
            />
          </>
        )}
        {mode === 'request' && (
          <div className={styles.dropzoneWrap}>
            <FileDropzone
              className={styles.dropzoneLarge}
              title={<Trans i18nKey="setting_templates_for_me_description" />}
              showName={false}
              placeholder=""
              accept={{
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/msword': ['.doc'],
                'application/rtf': ['.rtf'],
                'application/vnd.apple.pages': ['.pages'],
              }}
              maxFiles={20}
              onChange={handleAddFile}
            />
          </div>
        )}
        {mode === 'upload' && (
          <div className={styles.dropzoneWrap}>
            <FileDropzone
              className={styles.dropzone}
              title={<Trans i18nKey="move_template_for_setting_in_forma" />}
              showName={false}
              placeholder=""
              accept={{
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/msword': ['.doc'],
                'application/rtf': ['.rtf'],
                'application/vnd.apple.pages': ['.pages'],
              }}
              maxFiles={1}
              onChange={handleAddFile}
            />
            <Input
              name="create_template_name"
              placeholder={t('enter_template_name')}
              viewStyle="secondary"
              onChange={e => setName(e.target.value)}
              maxLength={100}
              value={name}
              showClearButton
            />
          </div>
        )}
        {mode === 'create' && (
          <Input
            name="create_template_name"
            placeholder={t('enter_template_name')}
            viewStyle="secondary"
            onChange={e => setName(e.target.value)}
            maxLength={100}
            value={name}
            showClearButton
          />
        )}
        {mode === 'select' && (
          <>
            <CreateTemplateButton
              name={t('create_in_editor')}
              icon="/icons/explorer/file_create.svg"
              onClick={() => setMode('create')}
            />
            <CreateTemplateButton
              name={t('upload_template_in_doc_format')}
              icon="/icons/explorer/file_word.svg"
              onClick={() => setMode('upload')}
            />
            <CreateTemplateButton
              name={t('configure_templates_for_me')}
              // description={tariff?.tariff.id === 'tariff_trial' && (
              //   <>
              //     {t('available_on_any_tariff')}
              //     <div>
              //       <Button
              //         className={styles.smallButton}
              //         size="extraSmall"
              //         fullWidth
              //       >
              //         {t('select_tariff')}
              //       </Button>
              //     </div>
              //   </>
              // )}
              icon="/images/go_to_knowledge.svg"
              iconWidth={137}
              onClick={() => setMode('request')}
              // onClick={() => tariff?.tariff.id !== 'tariff_trial' ? setMode('request') : navigate('/select-tariff')}
            />
          </>
        )}
        {mode === 'requestSuccess' && (
          <div className={styles.successModal}>
            <img className={styles.successImage} src="/images/onboarding_send_files_animation.svg" alt="" />
            <div className={styles.successTitle}>
              {t('thank_you')}
            </div>
            <div className={styles.successDescr}>
              {t('our_team_setting_templates_and_show_how')}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateTemplateModal;
