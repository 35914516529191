import { FC, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, LoadingButton, Modal, nanoid } from '@forma/forma-ui-kit';
import FillDocument from 'views/FillDocument';
import TrialEndModal from 'views/Guide/TrialEndModal';
import downloadFromUrl from 'helpers/downloadFromUrl';
import webView from 'helpers/webview';
import { TRIAL_ID } from 'data/constants';

import { ISidesListEditData, ITemplateSide, TFormatDocument } from 'interfaces/templates.interface';
import {
  useRenderTemplateMutation,
  useLazyDownloadRenderedTemplateQuery,
  ITemplateRenderData,
} from 'store/templates/templatesApi';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { useGetDocumentByIdQuery } from 'store/documents/documentsApi';

import styles from './ModalChangeDocument.module.css';

interface ModalChangeDocumentProps {
  open: boolean,
  onClose: () => void,
  id: string,
  name: string
}

const ModalChangeDocumentSkelet = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          icon={<ReactSVG src="/icons/download.svg" />}
          disabled
        >
          {t('download_pdf')}
        </LoadingButton>
        <LoadingButton
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          icon={<ReactSVG src="/icons/download.svg" />}
          disabled
        >
          {t('download_docx')}
        </LoadingButton>
      </ButtonsContainer>
    </div>
  );
};

const ModalChangeDocument: FC<ModalChangeDocumentProps> = ({ open, onClose, id, name }) => {
  const { t } = useTranslation();

  const { data: documentRender } = useGetDocumentByIdQuery(id, { skip: !open });
  const { data: currentTariff } = useGetCurrentTariffQuery();
  const [ downloadDocument, { isLoading: isDownloadLoading } ] = useLazyDownloadRenderedTemplateQuery();
  const [ renderTemplate, { isLoading: isRenderLoading } ] = useRenderTemplateMutation();

  const [ isFillingValid, setIsFillingValid ] = useState<boolean>(false);
  const [ sidesData, setSidesData ] = useState<ITemplateRenderData['sideValues']>([]);;
  const [ isChangedData, setChangedData ] = useState<boolean>(false);

  const [ isOpenTrialEnd, setOpenTrialEnd ] = useState<boolean>(false);

  useEffect(() => {
    webView.sendMessage(open ? 'documentIsOpen' : 'documentClosed');
  }, [open]);

  const handleDownloadDocument = (
    name: string,
    format: TFormatDocument = 'pdf',
  ) => {
    if (!documentRender) return;

    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.downloadDocument(documentRender.template.id, documentRender.id, `${name}.${format}`, format);
    } else {
      downloadDocument({ id: documentRender.template.id, rerenderId: documentRender.id, format })
        .unwrap()
        .then(url => {
          downloadFromUrl(url, `${name}.${format}`);
          onClose();
        });
    }
  };

  const handleRenderTemplate = (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument = 'pdf',
  ) => {
    if (!documentRender) return;
    if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
      setOpenTrialEnd(true);
      return;
    }

    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.renderTemplate(documentRender.template.id, `${name}.${format}`, { name, format, sideValues });
    } else {
      renderTemplate({ id: documentRender.template.id, name, format, sideValues })
        .unwrap()
        .then(url => {
          downloadFromUrl(url, `${name}.${format}`);
          onClose();
        });
    }
  };

  const handleClickDownload = (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument = 'pdf',
    isChanged: boolean
  ) => {
    if (isChanged) handleRenderTemplate(name, sideValues, format);
    else handleDownloadDocument(name, format);
  };

  // const sides = useMemo(() => documentRender?.sides.map((data, index) => ({ ...data, id: data.id ?? index+1+'' })), [documentRender]);
  // const valueSides = useMemo(() => (documentRender && sides) && documentRender.sideValues?.reduce((acc, data, index) => ({
  //   ...acc,
  //   [sides[index].id]: {
  //     ...data,
  //     id: sides[index].id,
  //     tableValues: data.tableValues?.length > 0 ? (
  //       data.tableValues.map(({ products, ...table }) => ({
  //         ...table,
  //         products: products.map(product => ({ id: nanoid(8), ...product }))
  //       }))
  //     ) : [],
  //     tattrValues: data.tattrValues ?? {},
  //     tattrErrors: {},
  //     // isValid: true,
  //   }
  // }), {}), [documentRender, sides]);

  const { sides, valueSides } = useMemo(() => {
    if (!documentRender?.sides) return { sides: undefined, valueSides: undefined };

    const sides: ITemplateSide[] = [];
    const valueSides: ISidesListEditData = {};
    for (let i=0; i < documentRender.sides.length; i++) {
      const side = documentRender.sides[i];
      const id = side.id ?? i+1+'';
      sides.push({ ...side, id });

      const sideValues = documentRender.sideValues?.[i] ?? {};
      valueSides[id] = {
        ...sideValues,
        id,
        tableValues: sideValues.tableValues?.length > 0 ? (
          sideValues.tableValues.map(({ products, ...table }) => ({
            ...table,
            products: products.map(product => ({ id: nanoid(8), ...product })),
            customProductsIds: []
          }))
        ) : [],
        tattrValues: sideValues.tattrValues ?? {},
        tattrErrors: {},
        // isValid: true,
      };
    }

    return { sides, valueSides };
  }, [documentRender]);

  return <Modal
    open={open}
    onClose={onClose}
    title={name}
    width="100%"
    maxWidth="1150px"
  >
    {documentRender ? (
      <div className={styles.root}>
        <ButtonsContainer className={styles.buttons}>
          <LoadingButton
            type="submit"
            className={styles.button}
            viewStyle="tertiary"
            size="small"
            onClick={() => handleClickDownload(documentRender.name, sidesData, 'pdf', isChangedData)}
            disabled={!isFillingValid}
            isLoading={isRenderLoading || isDownloadLoading}
            icon={<ReactSVG src="/icons/download.svg" />}
          >
            {t('download_pdf')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            className={styles.button}
            viewStyle="tertiary"
            size="small"
            onClick={() => documentRender && handleClickDownload(documentRender.name, sidesData, 'docx', isChangedData)}
            disabled={!isFillingValid}
            isLoading={isRenderLoading || isDownloadLoading}
            icon={<ReactSVG src="/icons/download.svg" />}
          >
            {t('download_docx')}
          </LoadingButton>
        </ButtonsContainer>
        {sides && (
          <FillDocument
            className={styles.filling}
            name={documentRender?.name}
            sides={sides}
            blocksTree={documentRender?.blocksTree}
            onClickDownload={handleClickDownload}
            isDownloadLoading={isRenderLoading || isDownloadLoading}
            valueSides={valueSides}
            setIsFillingValid={setIsFillingValid}
            setSidesDataForInnerRenderButton={setSidesData}
            onSetChanged={() => setChangedData(true)}
            showButtons={false}
            showHead={false}
          />
        )}
        <TrialEndModal
          open={isOpenTrialEnd}
          onClose={setOpenTrialEnd}
          title={t('trial_period_end')}
          description={t('trial_end_render_description')}
          image={<img src="/images/trial-end.svg" alt="" />}
        />
      </div>
    ) : (
      <ModalChangeDocumentSkelet />
    )}
  </Modal>;
};

export default ModalChangeDocument;