import { FC, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  isAllowed: boolean,
  redirectPath?: string,
  children: ReactElement
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ isAllowed, redirectPath = '/register', children }) => {
  const isMobileApp = window?.navigator?.userAgent === 'forma-mobile';
  if (isMobileApp && redirectPath === '/register') redirectPath = '/login';
  if (!isAllowed) return <Navigate to={redirectPath} replace={true} />;
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
