import { FC, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LangSwitchDropdown, ThemeContext } from '@forma/forma-ui-kit';
import Logo from 'components/Logo';
import TelegramButton from 'components/TelegramButton';
import { locales } from 'i18n';

import styles from './main-layout.module.css';

interface IMainLayoutProps {
  title: ReactNode,
  subtitle?: ReactNode,
  children: ReactNode,
  sideChildren?: ReactNode
}

const landingUrl = process.env.REACT_APP_LANDING_URL ?? '/';

const MainLayout: FC<IMainLayoutProps> = ({ title, subtitle, children, sideChildren }) => {
  const { i18n } = useTranslation();
  const { viewport, lang } = useContext(ThemeContext);

  const isMobileApp = window?.navigator?.userAgent === 'forma-mobile';

  if (!(i18n && i18n.language)) return null;

  const current = i18n.resolvedLanguage ? i18n.resolvedLanguage : i18n.language;

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <Logo showText={true} textClassName={styles.logoText} link={!isMobileApp ? `${landingUrl}/${lang}` : undefined} />
          </div>
          <div className={styles.lang}>
            <LangSwitchDropdown
              current={current}
              onChange={handleChangeLanguage}
              items={locales.map(lang => ({ key: lang, name: lang.toUpperCase() }))}
            />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.contentHead}>
            <h1 className={styles.title}>{title}</h1>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          </div>
          {children}
        </div>
      </div>
      {(sideChildren && viewport !== 'phone' && viewport !== 'tabletS') && (
        <div className={styles.side}>
          {sideChildren}
        </div>
      )}
      <div className={styles.question}>
        <TelegramButton />
      </div>
    </div>
  );
};

export default MainLayout;
