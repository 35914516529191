import { FC, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation, matchPath, Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { Modal } from '@forma/forma-ui-kit';
import FileExplorer from 'views/FileExplorer';
import { IFolderListChidlren, IFolderListTreeChidlren, IFoldersList } from 'interfaces/folders.interface';
import { IFoldersActionsProps, IFoldersEditingProps, IFoldersNavigationProps } from 'interfaces/fileExplorer.interface';
import type { BreadcrumbsItem } from '@forma/forma-ui-kit';

import {
  useGetFoldersTreeQuery,
  useGetFolderContentQuery,
  useSearchFoldersQuery,
  useMoveFolderMutation,
  useCopyFolderMutation,
  useRemoveFolderMutation,
  useChangeFolderMutation,
  useCreateFolderMutation,
  useFavouritesAddFolderMutation,
  useFavouritesRemoveFolderMutation
} from 'store/folders/foldersApi';
import {
  useMoveTemplateMutation,
  useCopyTemplateMutation,
  useRemoveTemplateMutation,
  useChangeTemplateMutation,
  useCreateTemplateMutation,
  useFavouritesAddTemplateMutation,
  useFavouritesRemoveTemplateMutation
} from 'store/templates/templatesApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { selectLayout, setOnboardingModal } from 'store/common/commonSlice';

import copyToClipboard from 'helpers/copyToClipboard';

import { useGetTemplateSidesQuery } from 'store/templates/templatesApi';
import { useGetGroupsListQuery } from 'store/groups/groupsApi';
import { selectOnboarding, selectUserPermissions } from 'store/user/userSlice';
import { useCopySharedTemplateMutation } from 'store/sharedTemplates/sharedTemplatesApi';

import styles from './FolderContent.module.css';

interface FolderContentProps {
  queryHook: any //fixme
}

const collectTreePath = (items: IFolderListTreeChidlren[], slug: string): string[] => {
  for (const folder of items) {
    if (folder.translatedName === slug) return [ folder.translatedName ];
    else if (folder.children) {
      const finded = collectTreePath(folder.children, slug);
      if (finded.length) return [ folder.translatedName, ...finded ];
    }
  }
  return [];
};

const FolderContent: FC<FolderContentProps> = ({ queryHook }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isFavourites = !!matchPath('/favourites/*', pathname);
  const layout = useAppSelector(selectLayout);
  const userPermissions = useAppSelector(selectUserPermissions);
  const onboarding = useAppSelector(selectOnboarding);
  const canChangeFolders = userPermissions?.includes('folders_templates');
  const [ openedFolder, setOpenedFolder ] = useState<string|null>(null);
  const [ searchQuery, setSeachQuery ] = useState<string>('');
  const [ updatedId, setUpdatedId ] = useStateIfMounted<string|null>(null);

  const [ templateToOpen, setTemplateToOpen ] = useState<IFolderListChidlren|null>(null);
  const [ sharedTemplateToOpen, setSharedTemplateToOpen ] = useState<IFolderListChidlren|null>(null);
  const [ isOpenEmpty, setOpenEmpty ] = useState<boolean>(false);
  const { data: templateSides, isFetching: isSidesLoading, isSuccess: sidesSucess } =
    useGetTemplateSidesQuery(templateToOpen?.id ?? '', { skip: !templateToOpen });

  const { data: foldersTree, isLoading: isFoldersTreeLoading }
    = useGetFoldersTreeQuery(undefined, { skip: layout !== 'list', pollingInterval: 5000 });

  const { data, isLoading: isFoldersLoading, error: pageError }
    = queryHook(slug, { skip: layout === 'list' && !isFavourites, pollingInterval: 5000 });
  const folders = data as IFoldersList;

  const { data: groups } = useGetGroupsListQuery(undefined, { skip: !canChangeFolders });
  const [ moveFolder ] = useMoveFolderMutation();
  const [ copyFolder ] = useCopyFolderMutation();
  const [ removeFolder ] = useRemoveFolderMutation();
  const [ changeFolder ] = useChangeFolderMutation();
  const [ createFolder ] = useCreateFolderMutation();
  const [ favouritesAddFolder ] = useFavouritesAddFolderMutation();
  const [ favouritesRemoveFolder ] = useFavouritesRemoveFolderMutation();

  const [ moveTemplate ] = useMoveTemplateMutation();
  const [ copyTemplate ] = useCopyTemplateMutation();
  const [ removeTemplate ] = useRemoveTemplateMutation();
  const [ changeTemplate ] = useChangeTemplateMutation();
  const [ createTemplate ] = useCreateTemplateMutation();
  const [ favouritesAddTemplate ] = useFavouritesAddTemplateMutation();
  const [ favouritesRemoveTemplate ] = useFavouritesRemoveTemplateMutation();

  const [ copySharedTemplate ] = useCopySharedTemplateMutation();

  useEffect(() => {
    if (onboarding && !onboarding.templates_list_tooltip && canChangeFolders && !isFavourites
      && ((folders && !folders.name) || foldersTree)
    ) {
      dispatch(setOnboardingModal('templates_list_tooltip'));
    }
    // eslint-disable-next-line
  }, [onboarding, folders, foldersTree, canChangeFolders, isFavourites]);

  useEffect(() => {
    if (pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404', { replace: true });
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (!isSidesLoading && sidesSucess) {
      if (templateSides && !templateSides?.sides.length) setOpenEmpty(true);
      else if (templateToOpen) navigate(`/fill-document/${templateToOpen.translatedName}`);
    }
    // eslint-disable-next-line
  }, [isSidesLoading, sidesSucess]);

  const setUpdatedTemplate = (id: string) => {
    setUpdatedId(id);
    setTimeout(() => setUpdatedId(null), 5000);
  };
  const handleShowContent = (folder: IFolderListChidlren) => {
    setOpenedFolder(folder.id !== openedFolder ? folder.id : null);
  };
  const handleCreateElement: IFoldersEditingProps['onCreate'] = async ({ name, type, parentid = folders?.id }) => {
    if (!name || !type) return null;
    const parentSlug = (slug && parentid === folders?.id) ? slug : null;
    const result = (type === 'folder') ? await createFolder({ name, parentid, parentSlug }) : await createTemplate({ name, folderid: parentid, parentSlug });
    if (!('data' in result)) return null;
    setUpdatedTemplate(result.data.id);
    return result.data;
  };
  const handleChangeElement: IFoldersEditingProps['onRename'] = ({ type, ...data }) => {
    if (!data) return null;
    const parentSlug = (slug && data.parentid === folders?.id) ? slug : null;
    if (type === 'folder') changeFolder({ ...data, parentSlug });
    else changeTemplate({ ...data, parentSlug });
  };
  const handleChangeAccess: IFoldersEditingProps['onChangeAccess'] = ({ type, ...data }) => {
    if (!data) return null;
    const parentSlug = (slug && data.parentid === folders?.id) ? slug : null;
    if (type === 'folder') changeFolder({ ...data, parentSlug, action: 'access' });
    else changeTemplate({ ...data, parentSlug, action: 'access' });
  };
  const handleCopyElement: IFoldersEditingProps['onCopy'] = ({ id, targetId, name, type }) => {
    if (id === targetId) return null;
    if (type === 'folder') {
      copyFolder({ id, folderid: targetId }).then(result => {
        if (!('data' in result)) return;
        dispatch(addNotification({ content: t('folder_copied'), type: 'SUCCESS' }));
        setUpdatedId(result.data.folderid ?? result.data.parentid ?? null);
        setTimeout(() => setUpdatedId(null), 5000);
      });
    } else {
      copyTemplate({ id, folderid: targetId }).then(result => {
        if (!('data' in result)) return;
        dispatch(addNotification({ content: t('file_copied'), type: 'SUCCESS' }));
        setUpdatedId(result.data.folderid ?? result.data.parentid ?? null);
        setTimeout(() => setUpdatedId(null), 5000);
      });
    }
  };
  const handleMoveElement: IFoldersEditingProps['onMove'] = ({ id, targetId, name, type }) => {
    if (id === targetId) return null;
    if (type === 'folder') {
      moveFolder({ id, parentid: targetId, name }).then(result => {
        if (!('data' in result)) return;
        dispatch(addNotification({ content: t('folder_moved'), type: 'SUCCESS' }));
        setUpdatedId(result.data.folderid ?? result.data.parentid ?? null);
        setTimeout(() => setUpdatedId(null), 5000);
      });
    } else {
      moveTemplate({ id, folderid: targetId, name }).then(result => {
        if (!('data' in result)) return;
        dispatch(addNotification({ content: t('file_moved'), type: 'SUCCESS' }));
        setUpdatedId(result.data.folderid ?? result.data.parentid ?? null);
        setTimeout(() => setUpdatedId(null), 5000);
      });
    }

  };
  const handleRemoveElement: IFoldersEditingProps['onRemove'] = ({ type, ...data }) => {
    if (!data) return null;
    if (type === 'folder') removeFolder({ ...data, parentSlug: slug });
    else removeTemplate({ ...data, parentSlug: slug });
  };

  const handleCopyShared: IFoldersActionsProps['onCopyShared'] = ({ id, targetId, type }) => {
    copySharedTemplate({ id, folderid: targetId ?? folders?.id });
  };
  const handleFavouritesToggle: IFoldersActionsProps['onFavouritesToggle'] = ({ id, type, checked }) => {
    if (type === 'folder') { checked ? favouritesAddFolder(id) : favouritesRemoveFolder(id); }
    else { checked ? favouritesAddTemplate(id) : favouritesRemoveTemplate(id); }
  };

  const { data: subfolders, isFetching: isLoadingSubfolder } =
    useGetFolderContentQuery(openedFolder ?? undefined, { skip: !openedFolder });

  const { data: searchResults, isFetching: isLoadingSearch } =
    useSearchFoldersQuery({ name: searchQuery, limit: 10, external: true }, { skip: !searchQuery || searchQuery === '' });

  const breadcrumbs: BreadcrumbsItem[] = [];
  if (slug || pathname === '/favourites') breadcrumbs.push({ name: t('my_templates'), to: '/templates', as: Link });
  if (pathname === '/favourites') breadcrumbs.push({ name: t('favourites') });

  let folderUrl: string = '/templates';

  if (folders?.path && folders?.path.length) {
    folders.path.forEach(({ name, translatedName }, index) => {
      const href = (breadcrumbs[index] && breadcrumbs[index].to) ? breadcrumbs[index].to : ''; // collect href from path
      breadcrumbs.push({ to: `${href}/${translatedName}`, name, as: Link });
      folderUrl += `/${translatedName}`;
    });
  }

  if (folders?.name) breadcrumbs.push({ name: folders.name });
  if (folders?.translatedName) folderUrl += `/${folders.translatedName}`;

  const handleBackFolder: IFoldersNavigationProps['onClickBack'] = () => {
    const pathArray = folderUrl.split('/');
    navigate(`${pathArray.slice(0, pathArray.length-1).join('/')}`);
  };
  const handleOpenFolder: IFoldersNavigationProps['onOpenFolder'] = (folder, parent) => {
    if (folder) {
      if (layout === 'list' && foldersTree) navigate(`${folderUrl}/${collectTreePath(foldersTree, folder.translatedName).join('/')}`);
      else navigate(`${folderUrl}/${parent?.translatedName ? `${parent.translatedName}/` : ''}${folder.translatedName}`);
    } else navigate('/templates');
    setSeachQuery('');
  };
  const handleOpenTemplate = (template: IFolderListChidlren) => {
    setTemplateToOpen(template);
  };
  const handleOpenSharedTemplate = (template: IFolderListChidlren) => {
    setSharedTemplateToOpen(template);
  };

  const handleOpenSharedFolder = (folder: IFolderListChidlren) => {
    if (folder.id === 'main') navigate('/static-templates');
    else if (folder.id === 'packs') navigate('/templates-packs');
    else if (folder.parentid) navigate(`/static-templates/${folder.parentid}?filterId=${folder.id}`);
    else navigate(`/static-templates/${folder.id}`);
  };

  const handleCopyLink: IFoldersActionsProps['onCopyLink'] = ({ translatedName, parentSlug, type }) => {
    const origin = window.location.origin;
    let url = '';
    if (type === 'template') url = `${origin}/fill-document/${translatedName}`;
    else {
      url = (layout === 'list' && foldersTree) ?
        `${origin}${folderUrl}/${collectTreePath(foldersTree, translatedName).join('/')}`
        : `${origin}${folderUrl}/${parentSlug ? `${parentSlug}/` : ''}${translatedName}`;
    }

    const res = copyToClipboard(url);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
  };

  useEffect(() => {
    if (pathname !== '/favourites' && !isFoldersLoading && folders) {
      if (pathname !== folderUrl && folders.translatedName === slug) navigate(folderUrl, { replace: true });
    }
    // eslint-disable-next-line
  }, [isFoldersLoading, pathname, folderUrl]);

  const formaDefaultForler: IFolderListChidlren = {
    id: 'main', type: 'folder', external: true, name: t('forma_templates'), translatedName: '', groups: [], favorite: true
  };
  const formaPacksForler: IFolderListChidlren = {
    id: 'packs', type: 'folder', external: true, name: t('templates_packs'), translatedName: '', groups: [], favorite: true
  };

  return (
    <AdminLayout title={t('my_templates')} breadcrumbs={{ items: breadcrumbs.length ? breadcrumbs : null }}>
      <PageTitle>{t('site_name') + ' – ' + t('my_templates')}</PageTitle>
      <FileExplorer
        foldersTree={
          isFavourites ? (
            folders?.items ? [ formaDefaultForler, formaPacksForler, ...folders?.items ] : [ formaDefaultForler, formaPacksForler ]
          ) : (
            foldersTree && [ formaDefaultForler, formaPacksForler, ...foldersTree ]
          )
        }
        folder={folders}
        userGroups={groups}
        items={folders?.items && (
          folders?.id ? (
            folders.items
          ) : [
            formaDefaultForler,
            formaPacksForler,
            ...folders.items
          ]
        )}
        active={openedFolder}
        updated={updatedId}
        isLoading={isFoldersLoading || isFoldersTreeLoading || !!pageError}

        subitems={openedFolder ? subfolders?.items : undefined}
        subitemsIsLoading={isLoadingSubfolder}

        actions={{
          onCopyLink: handleCopyLink,
          onFavouritesToggle: handleFavouritesToggle,
          onCopyShared: handleCopyShared
        }}
        search={{
          results: (searchQuery && searchQuery !== '') ? searchResults : undefined,
          isLoading: isLoadingSearch,
          onChange: setSeachQuery
        }}
        editing={{
          onMove: handleMoveElement,
          onCopy: handleCopyElement,
          onDuplicate: handleCopyElement,
          onRemove: handleRemoveElement,
          onRename: handleChangeElement,
          onCreate: !isFavourites ? handleCreateElement : undefined,
          onChangeAccess: handleChangeAccess
        }}
        favourites={{
          isOpen: !!isFavourites,
          href: isFavourites ? '/templates' : '/favourites'
        }}
        navigation={{
          onClickBack: handleBackFolder,
          onShowFolderContent: handleShowContent,
          onOpenFolder: handleOpenFolder,
          onOpenTemplate: handleOpenTemplate,
          onOpenSharedTemplate: handleOpenSharedTemplate,
          onOpenSharedForlder: handleOpenSharedFolder
        }}
      />

      <Modal
        size="small"
        open={isOpenEmpty}
        onClose={() => { setOpenEmpty(false); setTemplateToOpen(null); }}
        buttons={[
          {
            viewStyle: 'primary',
            children: t('open_document_in_editor'),
            onClick: () => templateToOpen?.translatedName && navigate(`/editor/${templateToOpen.translatedName}`)
          }
        ]}
      >
        <ReactSVG src="/images/empty-doc-animation.svg" />
        <p className={styles.emptyDescription}>
          <Trans i18nKey="empty_document_sides_description" />
        </p>
      </Modal>
      <Modal
        title={t('select_action')}
        size="small"
        open={!!sharedTemplateToOpen?.external}
        onClose={() => setSharedTemplateToOpen(null)}
        buttonsClassName={styles.copyButtons}
        buttons={[
          {
            viewStyle: 'primary',
            children: t('fill'),
            className: styles.fillButton,
            onClick: async () => {
              if (!sharedTemplateToOpen) return;
              const res = await copySharedTemplate({ id: sharedTemplateToOpen.id, folderid: folders?.id });
              if ('data' in res && res.data) navigate(`/fill-document/${res.data?.translatedName}`);
            }
          },
          {
            viewStyle: 'textLight',
            children: t('edit'),
            className: styles.editButton,
            onClick: async () => {
              if (!sharedTemplateToOpen) return;
              const res = await copySharedTemplate({ id: sharedTemplateToOpen.id, folderid: folders?.id });
              if ('data' in res && res.data) navigate(`/editor/${res.data?.translatedName}`);
            }
          }
        ]}
      >
        <p className={styles.copyDescription}>
          <Trans i18nKey="template_will_be_copied_notice" />
        </p>
      </Modal>
    </AdminLayout>
  );
};

export default FolderContent;
