import {
  ColontitulData,
  IRenderSideValues,
  ITemplate,
  ITemplateBlocksTree,
  ITemplateBorders,
  ITemplateContent,
  ITemplateSide,
  ITemplateTable,
  ITemplatesListSides,
  TFormatDocument
} from 'interfaces/templates.interface';
import { baseApi } from '../store';
import { IFolderListTreeChidlren, IFoldersList } from 'interfaces/folders.interface';
import { foldersApi } from 'store/folders/foldersApi';

export interface ITemplateContentData {
  id: string,
  content: string,
  sides: {
    id: string,
    name: string,
    tattrFolders: {
      id: string,
      name: string,
      tattrs: {
        id: string,
        from: 'strictattr'|'cattr'
      }[]
    }[],
    tables?: ITemplateTable[]
  }[],
  border: ITemplateBorders,
  runningTitles: ColontitulData[],
  landscape: boolean,
  blocksTree?: ITemplateBlocksTree
}

export interface ITemplateRenderData {
  id: string,
  name: string,
  sideValues: IRenderSideValues[],
  format?: TFormatDocument
}

interface ICreatedTemplate {
  id: string,
  name: string,
  favorite?: boolean,
  parentid?: string,
  folderid?: string,
  translatedName: string,
  groups?: string[]
}
interface IDeletedTemplate {
  id: string
}
interface ICreateTemplateData {
  name: string,
  favorite?: boolean,
  parentid?: string|null,
  folderid?: string|null,
  parentSlug?: string|null
}
interface IChangeTemplateData {
  id: string,
  action?: 'access',
  name: string,
  favorite?: boolean,
  parentid?: string|null,
  folderid?: string|null,
  parentSlug?: string|null
}
interface IDeleteTemplateData {
  id: string,
  translatedName: string,
  parentSlug?: string|null
}


export const templatesApi = baseApi.enhanceEndpoints(({ addTagTypes: ['Template', 'TemplateSides'] })).injectEndpoints({
  endpoints: builder => ({
    getTemplateByName: builder.query<ITemplate, string>({
      query: slug => ({
        url: `/templates/byName/${slug}`,
        method: 'GET'
      }),
      providesTags: (result, error, slug) => [{ type: 'Template', id: slug }],
    }),
    getTemplateById: builder.query({
      query: id => ({
        url: `/templates/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'Template', id }],
    }),
    getTemplateContent: builder.query<ITemplateContent, string>({
      query: id => ({
        url: `/templates/${id}/content`,
        method: 'GET'
      }),
      // transformResponse: (result: ITemplateContent) => {
      //   const withGroups: ITemplateContent = {
      //     content: result.content,
      //     border: result.border,
      //     sides: result.sides.map(({ id, name, tattrFolders }, index) => ({ id: id ?? index+1+'', name, tattrFolders }))
      //   };
      //   return withGroups;
      // },
      providesTags: (result, error, id) => [{ type: 'Template', id }],
    }),
    getTemplateSides: builder.query<{ sides: ITemplateSide[], blocksTree?: ITemplateBlocksTree }, string>({
      query: id => ({
        url: `/templates/${id}/sides`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'Template', id }],
    }),
    getTemplatesListSides: builder.query<ITemplatesListSides, { ids: string[] }>({
      query: data => ({
        url: '/templates/sides',
        method: 'GET',
        params: data
      }),
      providesTags: ['TemplateSides'],
      transformResponse: (result: { id: string, name: string, sides: ITemplateSide[], blocksTree?: ITemplateBlocksTree }[]) =>
        result.reduce((prev, current) => ({ ...prev, [current.id]: current }), {})
    }),

    createTemplate: builder.mutation<ICreatedTemplate, ICreateTemplateData>({
      query: data => ({
        url: '/templates',
        method: 'POST',
        body: data
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'Folders', id: data.parentid ?? undefined },
        { type: 'Folders', id: data.folderid ?? undefined },
        'Search',
        'FoldersTree'
      ],
      async onQueryStarted(data, { dispatch, getState, queryFulfilled }) {
        const id = data.parentid ? data.parentid : data.folderid;
        const updateDraft = (data: ICreateTemplateData, draft: IFoldersList) => {
          const rndId = (Math.random() * (99999 - 10000) + 10000)+'';
          draft.items.push({ ...data, isCreated: true, id: rndId, translatedName: '', groups: [], type: 'template' });
        };
        const updateDraftTree = (data: ICreateTemplateData, draft: IFolderListTreeChidlren[]) => {
          const rndId = (Math.random() * (99999 - 10000) + 10000)+'';
          // todo: make this code for multilevel
          draft.push({ ...data, isCreated: true, id: rndId, translatedName: '', groups: [], type: 'template' });
        };

        const putResult1 = dispatch(
          foldersApi.util.updateQueryData('getFolderContent', id ?? undefined, (draft) => updateDraft(data, draft))
        );
        const putResult2 = data.parentSlug && dispatch(
          foldersApi.util.updateQueryData('getFolderContentByName', data.parentSlug, (draft) => updateDraft(data, draft))
        );
        const putResult3 = dispatch(
          foldersApi.util.updateQueryData('getFoldersTree', undefined, (draft) => updateDraftTree(data, draft))
        );

        try {
          await queryFulfilled;
        } catch {
          putResult1.undo();
          if (putResult2) putResult2.undo();
          putResult3.undo();
        }
      },
    }),
    changeTemplate: builder.mutation<ICreatedTemplate, IChangeTemplateData>({
      query: ({ id, action, ...data }) => ({
        url: `/templates/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Folders', 'Search', 'Favourites', 'FoldersTree', 'TemplateSides'],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const parentid = data.parentid ? data.parentid : data.folderid;
        const recursiveUpdate = (items: IFolderListTreeChidlren[], data: IChangeTemplateData) => {
          for (let i = 0; i < items.length; i++) {
            if (items[i].id === data.id) {
              if (data.action === 'access') items[i].isAccessAdd = true;
              items[i] = { ...items[i], ...data };
              break;
            }
            const item = items[i];
            if (item.children) items[i].children = recursiveUpdate(item.children, data);
          }
          return items;
        };
        const updateDraft = (draft: IFoldersList, data: IChangeTemplateData) => {
          for (let i = 0; i < draft.items.length; i++) {
            const item = draft.items[i];
            if (item.id === data.id) {
              if (data.action === 'access') item.isAccessAdd = true;
              draft.items[i] = { ...item, ...data };
              break;
            }
          }
        };

        const putResult1 = dispatch(
          foldersApi.util.updateQueryData('getFolderContent', parentid ?? undefined, (draft) => updateDraft(draft, data))
        );
        const putResult2 = data.parentSlug && dispatch(
          foldersApi.util.updateQueryData('getFolderContentByName', data.parentSlug, (draft) => updateDraft(draft, data))
        );
        const putResult3 = dispatch(
          foldersApi.util.updateQueryData('getFoldersTree', undefined, (draft) => recursiveUpdate(draft, data))
        );

        try {
          await queryFulfilled;
        } catch {
          putResult1.undo();
          if (putResult2) putResult2.undo();
          putResult3.undo();
        }
      },
    }),
    copyTemplate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/templates/${id}/copy`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Folders', 'Search', 'FoldersTree'],
      // todo: add optimistic copying
    }),
    moveTemplate: builder.mutation<ICreatedTemplate, IChangeTemplateData>({
      query: ({ id, ...data }) => ({
        url: `/templates/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Folders', 'Search', 'FoldersTree'],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const recursiveRemove = (items: IFolderListTreeChidlren[], id: string) => {
          return items.filter(item => {
            if (item.children) item.children = recursiveRemove(item.children, id);
            return item.id !== id;
          });
        };
        // todo: add optimistic paste
        const putResult1 = dispatch(
          foldersApi.util.updateQueryData('getFolderContent', undefined, (draft) => {
            const index = draft.items.findIndex(item => item.id === data.id);
            draft.items.splice(index, 1);
          })
        );
        const putResult2 = dispatch(
          foldersApi.util.updateQueryData('getFoldersTree', undefined, (draft) => {
            draft = recursiveRemove(draft, data.id);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          putResult1.undo();
          putResult2.undo();
        }
      },
    }),
    removeTemplate: builder.mutation<IDeletedTemplate, IDeleteTemplateData>({
      query: ({ id, ...data }) => ({
        url: `/templates/${id}`,
        method: 'DELETE',
        body: data
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'Template', id: data.id }, { type: 'Template', id: data.translatedName }, 'TemplateSides', 'Folders', 'Search', 'Favourites', 'FoldersTree'
      ],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const removeItem = (data: IDeleteTemplateData, draft: IFoldersList) => {
          const index = draft.items.findIndex(item => item.id === data.id);
          if (index !== -1) draft.items.splice(index, 1);
        };
        const recursiveRemove = (data: IDeleteTemplateData, draft: IFolderListTreeChidlren[]) => {
          const index = draft.findIndex(item => item.id === data.id);
          if (index !== -1) draft.splice(index, 1);
          draft.forEach(item => {
            if (item.children) recursiveRemove(data, item.children);
          });
        };

        const putResult1 = dispatch(
          foldersApi.util.updateQueryData('getFolderContent', undefined, (draft) => removeItem(data, draft))
        );
        const putResult2 = data.parentSlug && dispatch(
          foldersApi.util.updateQueryData('getFolderContentByName', data.parentSlug, (draft) => removeItem(data, draft))
        );
        const putResult3 = dispatch(
          foldersApi.util.updateQueryData('getFoldersTree', undefined, (draft) => recursiveRemove(data, draft))
        );

        try {
          await queryFulfilled;
        } catch {
          putResult1.undo();
          if (putResult2) putResult2.undo();
          putResult3.undo();
        }
      },
    }),
    favouritesAddTemplate: builder.mutation<ICreatedTemplate, string>({
      query: id => ({
        url: `/templates/favorites/${id}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Folders', 'Search', 'Favourites', 'FoldersTree'],
      // todo: add optimistic update
    }),
    favouritesRemoveTemplate: builder.mutation<ICreatedTemplate, string>({
      query: id => ({
        url: `/templates/favorites/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Folders', 'Search', 'Favourites', 'FoldersTree'],
      onQueryStarted(id, { dispatch, queryFulfilled }) {
        const putResult = dispatch(
          foldersApi.util.updateQueryData('getFavourites', undefined, (draft: IFoldersList) => {
            draft.items = draft.items.filter(item => item.id !== id);
          })
        );
        queryFulfilled.catch(putResult.undo);
      },
    }),

    saveTemplate: builder.mutation<ITemplateContent, ITemplateContentData>({
      query: data => ({
        url: `/templates/${data.id}/content`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: (result, error, data) => [{ type: 'Template', id: data.id }, 'TemplateSides']
    }),
    renderHtml: builder.mutation<string, ITemplateRenderData>({
      query: ({ id, ...data }) => ({
        url: `/templates/${id}/html`,
        method: 'POST',
        body: data,
      }),
    }),
    renderTemplate: builder.mutation<string, ITemplateRenderData>({
      query: ({ id, ...data }) => ({
        url: `/templates/${id}/render`,
        method: 'POST',
        body: data,
        responseHandler: async (response: Response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        },
      }),
      invalidatesTags: ['Documents']
    }),
    downloadRenderedTemplate: builder.query<string, { id: string, rerenderId: string, format: TFormatDocument }>({
      query: ({ id, rerenderId, format }) => ({
        url: `/templates/${id}/render/${rerenderId}?format=${format}`,
        method: 'GET',
        responseHandler: async (response: Response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        },
      }),
      providesTags: (result, error, { id, rerenderId, format }) => [{ type: 'Documents', id, rerenderId, format }],
    }),
    convertTemplate: builder.mutation<{ content: string }, FormData>({
      query: data => ({
        url: '/converters/doctohtml',
        method: 'POST',
        body: data
      }),
    }),
    importTemplate: builder.mutation<ICreatedTemplate, FormData>({
      query: data => ({
        url: '/templates/doc',
        method: 'POST',
        body: data
      }),
    }),
    addOnboardingTemplates: builder.mutation({
      query: data => {
        return {
          url: `/templates/onboarding`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Folders', 'Search', 'FoldersTree']
    }),
  })
});

export const {
  useGetTemplateByNameQuery,
  useGetTemplateByIdQuery,
  useGetTemplateContentQuery,
  useGetTemplateSidesQuery,
  useLazyGetTemplateSidesQuery,
  useGetTemplatesListSidesQuery,

  useMoveTemplateMutation,
  useCopyTemplateMutation,
  useRemoveTemplateMutation,
  useChangeTemplateMutation,
  useCreateTemplateMutation,
  useFavouritesAddTemplateMutation,
  useFavouritesRemoveTemplateMutation,

  useSaveTemplateMutation,
  useRenderHtmlMutation,
  useRenderTemplateMutation,
  useLazyDownloadRenderedTemplateQuery,
  useConvertTemplateMutation,
  useImportTemplateMutation,
  useAddOnboardingTemplatesMutation,
} = templatesApi;
