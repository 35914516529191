import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import { LoadingButton, Input, PhoneInput, ButtonsContainer, phonemasks, ThemeContext } from '@forma/forma-ui-kit';
import PromoInput from 'views/profile/PromoInput';
import getValidationSchema from 'data/validationSchema';
import { analytics } from 'helpers/analytics';
import { getCookie } from 'helpers/cookies';

import { IPromocode } from 'interfaces/tariffs.interface';

import { useRegisterUserMutation } from 'store/auth/authApi';
import { useLazyCheckPublicPromocodeQuery } from 'store/tariffs/tariffsApi';

import styles from './Account.module.css';

interface FormValues {
  name: string,
  phone: string
}

const landingUrl = process.env.REACT_APP_LANDING_URL;

const Register = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { viewport, lang } = useContext(ThemeContext);

  const [ promoData, setPromoData ] = useState<IPromocode|null>(null);
  const [ isPromoError, setPromoError ] = useState<boolean>(false);

  const [ registerUser, { isLoading, isSuccess } ] = useRegisterUserMutation();
  const [ getPromocodeInfo ] = useLazyCheckPublicPromocodeQuery();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema([ 'name', 'phone' ]))
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const roistatVisit = getCookie('roistat_visit');
    const cookieUtm = getCookie('utm');
    const utm: { [key: string]: string } = cookieUtm ? JSON.parse(decodeURIComponent(cookieUtm)) : {};
    const registerQuery = Object.entries(utm).map(([ key, value ]) => ({ key, value }));
    registerUser({ ...data, login: location?.state?.login, password: location?.state?.password, roistatVisit, registerQuery });
  };

  const handleSubmitPromo = async (code: string) => {
    const result = await getPromocodeInfo(code);
    if ('data' in result && result.data) {
      setPromoData(result.data);
      setPromoError(false);
      localStorage.setItem('promocode', code);
    } else {
      setPromoData(null);
      setPromoError(true);
      localStorage.removeItem('promocode');
    }
  };

  useEffect(() => {
    if (isSuccess) navigate('/templates');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (!location?.state?.login || !location?.state?.password) navigate('/register');

  return (
    <MainLayout title={t('register2')}>
      <PageTitle>{t('site_name') + ' – ' + t('register')}</PageTitle>
      {viewport !== 'phone' && (
        <div className={styles.subtitle}>
          {t('or')} <Link to="/login">{t('do_login')}</Link>
        </div>
      )}
      <div className={styles.trial}>{t('account.trial_notice')}</div>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
        {/* <div className={styles.registeredEmail}>
          <div>{t('account.you_registered_as')}: <b>{location?.state?.login}</b></div>
          <Link className={styles.registeredChange} to="/register">{t('change')}</Link>
        </div> */}
        <div className={styles.inputs}>
          <Controller
            control={control}
            name="name"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="name"
                placeholder={t('account.how_is_your_name')}
                showClearButton
                autoComplete="name"
                error={!!errors?.name?.message}
                errorMessage={errors && errors.name && t('errors.' + errors.name.message)}
                {...rest}
                {...register}
                value={value}
                data-testid="name"
              />
            )}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field: { value = '', ...rest } }) => (
              <PhoneInput
                masks={phonemasks}
                defaultMaskCode={(i18n.resolvedLanguage === 'en' || !i18n.resolvedLanguage) ? 'US' : i18n.resolvedLanguage.toUpperCase()}
                placeholder={t('phone')}
                showClearButton
                error={!!errors?.phone?.message}
                errorMessage={errors && errors.phone && t('errors.' + errors.phone.message)}
                autoComplete="phone"
                {...rest}
                {...register}
                value={value}
                data-testid="phone"
              />
            )}
          />
        </div>
        {/* <div className={styles.errors}>
          {(isError && error && error.data && error.data.error) &&
            error.data.error.map((value, index) => <div className={styles.error} key={index}>{value}</div>)
          }
        </div> */}

        <div className={styles.promocode}>
          <div className={styles.promocodeTitle}>
            {t('account.do_you_have_promocode')}
          </div>
          <div className={styles.promocodeInputs}>
            <PromoInput
              info={promoData}
              isError={isPromoError}
              onChange={handleSubmitPromo}
            />
          </div>
        </div>

        <ButtonsContainer className={styles.buttonsContainer}>
          <LoadingButton
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            onClick={analytics.createAccount}
            fullWidth
            shadow
            data-testid="create"
          >
            {t('account.create_account')}
          </LoadingButton>
        </ButtonsContainer>
        <div className={styles.agreement}>
          <Trans
            i18nKey="account.register_oferta"
            values={{
              button: t('account.create_account'),
            }}
            components={{
              // eslint-disable-next-line
              agreement_tag: <a href={`${landingUrl}/${lang}/license/`} target="_blank" />,
              // eslint-disable-next-line
              privacy_tag: <a href={`${landingUrl}/${lang}/privacy/`} target="_blank" />
            }}
          />
        </div>
      </form>
    </MainLayout>
  );
};

export default Register;
