import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './trial-notification.module.css';

const TrialNotification = ({ trialDays }: { trialDays?: number }) => {
  const { t } = useTranslation();

  if (trialDays || trialDays === 0) return (
    <div className={classNames(styles.root, trialDays < 4 ? 'error' : (trialDays < 7 ? 'attention' : 'success'))}>
      <span className={styles.text}>
        <ReactSVG className={styles.icon} src="/icons/clock.svg" wrapper="span" />
        {trialDays ? (
          <span>{t('count_days', { count: trialDays })} {t('until_trial_end')}</span>
        ) : (
          <span style={{ fontWeight: 600 }}>{t('trial_expired')}</span>
        )}
      </span>

      <Link
        className={styles.link}
        to="/select-tariff"
      >
        {trialDays ? t('subscription.subscribe_now') : t('subscription.pay')}
      </Link>
    </div>
  );

  return null;
};

export default TrialNotification;
