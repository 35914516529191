import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input, LoadingButton, nanoid } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import DistributeSides, { type SortSide } from '../DistributeSides';
import { parsePackSides } from '../DistributeSides/utils';

import { ITemplatesListSides, ITemplatesPack } from 'interfaces/templates.interface';

import styles from './templates-pack-fill.module.css';

interface TemplatesPackFillProps {
  pack?: ITemplatesPack,
  isLoading: boolean,
  isSidesLoading: boolean,
  templateName: string,
  templatesSides?: ITemplatesListSides,
  onSave: (name: string, sides: SortSide[]) => void,
  isSaveLoading: boolean
}

const TemplatesPackFill: FC<TemplatesPackFillProps> = ({
  pack, isLoading, isSidesLoading, templateName, templatesSides, onSave, isSaveLoading
}) => {
  const { t } = useTranslation();
  const [ isShowRename, setShowRename ] = useState(false);
  const [ name, setName ] = useState('');
  const [ sortedSides, setSortedSides ] = useState<SortSide[]>([]);

  useEffect(() => {
    setName(templateName);
  }, [templateName]);

  useEffect(() => {
    if (!templatesSides) return;
    if (pack) setSortedSides(parsePackSides(templatesSides, pack));
    else setSortedSides([{ id: nanoid(8), name: `${t('side')} 1`, sideIndices: [] }]);
    // eslint-disable-next-line
  }, [pack, templatesSides]);

  const handleClickSave = () => {
    if (name.length < 3) setShowRename(true);
    else onSave(name, sortedSides);
  };

  const sidesCount = sortedSides ? sortedSides.reduce((prev, current) => (prev + current.sideIndices.length), 0) : 0;
  const templatesSidesCount =
    templatesSides ? Object.values(templatesSides).reduce((prev, current) => (prev + current.sides.length), 0) : 0;

  return (
    <div className={styles.root} id="templates_pack_fill">
      <div className={styles.row}>
        <div className={styles.column}>
          <h1 className={styles.title}>{t('distribute_sides')}</h1>
        </div>
        <div className={styles.right}>
          {!(isLoading || isSidesLoading) ? (
            <div className={styles.column}>
              <div className={styles.buttons}>
                <LoadingButton
                  id="save_templates_pack_button"
                  className={styles.saveButton}
                  isLoading={isSaveLoading}
                  onClick={handleClickSave}
                  viewStyle="secondary"
                  shadow
                  disabled={sidesCount !== templatesSidesCount}
                >
                  {t('save_templates_pack')}
                </LoadingButton>
              </div>
            </div>
          ) : (
            <div className={classNames('skeleton-loader')} />
          )}
        </div>
      </div>

      <DistributeSides
        isLoading={isLoading || isSidesLoading}
        templatesSides={templatesSides}
        sortedSides={sortedSides}
        onSortSides={setSortedSides}
      />

      <Modal
        size="small"
        open={isShowRename}
        onClose={() => setShowRename(false)}
        title={t('save_templates_pack')}
        buttons={[
          {
            id: 'modal_save_pack_button',
            children: t('save'),
            viewStyle: 'primary',
            onClick: handleClickSave,
            disabled: name.length < 3,
            closing: false,
            isLoading: isSaveLoading
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          }
        ]}
      >
        <div className={styles.renameModal}>
          <Input
            id="rename_templates_pack_input"
            name="pack_name"
            type="text"
            placeholder={t('templates_pack_name')}
            onChange={e => setName(e.target.value)}
            maxLength={100}
            showClearButton
            value={name}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TemplatesPackFill;
