import { FC, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingIndicator, Modal, Switcher, ThemeContext } from '@forma/forma-ui-kit';
import OrganizationsList from 'views/profile/OrganizationsList';
import EditOrganizationModal from 'views/profile/EditOrganizationModal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/userSlice';
import { useCreateExternalFillOwnerMutation, useGetExternalFillOwnersQuery } from 'store/externalfill/externalfillApi';

import styles from './select-owner-modal.module.css';
import { useGetBillingInfoQuery } from 'store/billing/billingApi';
import { addNotification } from 'store/notifications/notificationsSlice';

interface SelectOwnerModalProps {
  open: boolean,
  onClose: () => void,
  onSubmit: (ownerId: string) => void,
  isLoading?: boolean,
  isSign?: boolean
}

const landingUrl = process.env.REACT_APP_LANDING_URL;

const SelectOwnerModal: FC<SelectOwnerModalProps> = ({ open, onClose, onSubmit, isLoading, isSign }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ acceptTerms, setAcceptTerms ] = useState<boolean>(false);
  const [ selectedOwnerId, setSelectedOwnerId ] = useState<string|null>(null);
  const [ isOpenCreate, setOpenCreate ] = useState<boolean>(false);
  const user = useAppSelector(selectUserData);
  const { lang } = useContext(ThemeContext);

  const { data: owners, isLoading: isOwnersLoading } = useGetExternalFillOwnersQuery(undefined, { skip: !open });
  const [ createOwner, { isLoading: isCreateLoading } ] = useCreateExternalFillOwnerMutation();
  const { data: billingInfo, isLoading: isBillingLoading } = useGetBillingInfoQuery(undefined, { skip: !open });

  useEffect(() => {
    if (!owners?.length || selectedOwnerId) return;
    const approvedOrganization = owners.find(({ approved }) => approved);
    if (approvedOrganization) setSelectedOwnerId(approvedOrganization.id);

    // eslint-disable-next-line
  }, [owners]);

  useEffect(() => {
    // if (open && owners && !owners.length) setTimeout(() => setOpenCreate(true));

    if (open && owners && !owners.length && billingInfo && user) createOwner({
      name: billingInfo.companyname,
      address: billingInfo.address,
      inn: billingInfo.inn,
      kpp: billingInfo.kpp,
      email: user.login,
      phone: billingInfo.phone
    });

    // eslint-disable-next-line
  }, [owners, open, billingInfo]);

  const handleSelectOwner = (id: string, approved?: boolean) => {
    if (approved) setSelectedOwnerId(id);
    else dispatch(addNotification({ content: t('organization_is_not_approved'), type: 'ERROR' }));
  };

  return (
    <Modal
      title={t('select_organization_collect_privacy')}
      open={open}
      onClose={onClose}
      size="large"
      buttons={[
        {
          children: t(isSign ? 'sign' : 'save'),
          className: styles.save,
          isLoading: isLoading,
          disabled: !selectedOwnerId || !acceptTerms,
          onClick: () => selectedOwnerId && onSubmit(selectedOwnerId),
          closing: false
        }
      ]}
    >
      {isOwnersLoading || isCreateLoading || isBillingLoading ? (
        <LoadingIndicator />
      ) : (
        !!(owners?.length) && (
          <OrganizationsList
            items={owners}
            selected={selectedOwnerId}
            onSelect={handleSelectOwner}
            showButtons
          />
        )
      )}
      <Switcher
        name="accept_agreement"
        onChange={e => setAcceptTerms(e.target.checked)}
        checked={acceptTerms}
        containerClass={styles.checkbox}
        size="small"
        label={
          <Trans
            i18nKey="i_accept_proccessing_personal_data"
            components={{
              // eslint-disable-next-line
              link_tag: <a
                href={`${landingUrl}/${lang}/poruchenie_na_obrabotku/${user?.workspace.id}/${selectedOwnerId}`}
                target="_blank"
              />
            }}
          />
        }
      />
      <EditOrganizationModal
        open={isOpenCreate}
        onClose={() => setOpenCreate(false)}
        onSubmit={setSelectedOwnerId}
      />
    </Modal>
  );
};

export default SelectOwnerModal;
