import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import FillRequestModal from 'views/Guide/FillRequestModal';

import { useAppSelector } from 'store/hooks';
import { useSetOnboardingMutation } from 'store/user/userApi';
import { selectOnboarding } from 'store/user/userSlice';
import { selectOnboardingModal } from 'store/common/commonSlice';

import styles from './send-templates-notification.module.css';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { TRIAL_ID } from 'data/constants';

const SendTemplatesNotification = () => {
  const { t } = useTranslation();
  const onboarding = useAppSelector(selectOnboarding);
  const onboardingModal = useAppSelector(selectOnboardingModal);
  const [ isShow, setShow ] = useState(false);
  const [ isSendOpen, setSendOpen ] = useState(false);

  const { data: currentTariff } = useGetCurrentTariffQuery();
  const [ setOnboardingItem ] = useSetOnboardingMutation();

  useEffect(() => {
    const isShow = !!localStorage.getItem('setting_templates_notification');
    if (isShow) setShow(true);
  }, []);

  useEffect(() => {
    if (
      onboarding && !onboarding.edit_template_modal && onboardingModal === 'edit_template_modal'
      && currentTariff && (currentTariff?.tariff.id === TRIAL_ID || !currentTariff.isPaid)
    ) {
      localStorage.setItem('setting_templates_notification', 'true');
      setShow(true);
    }
  }, [onboarding, onboardingModal, currentTariff]);

  const handleClose = () => {
    setOnboardingItem({ setting_templates_notification: true });
    localStorage.removeItem('setting_templates_notification');
    setShow(false);
  };

  if (!isShow) return null;

  return (
    <div className={classNames(styles.root, 'success')}>
      <div className={styles.content}>
        <ReactSVG className={styles.icon} src="/icons/documents-list.svg" wrapper="div" />
        <div className={styles.text}>
          <div className={styles.title}><Trans i18nKey="send_templates_title" components={{ span: <span /> }} /></div>
          <div className={styles.description}>{t('send_templates_description')}</div>
        </div>
      </div>

      <div className={styles.buttons}>
        <Button
          className={styles.button}
          size="extraSmall"
          onClick={() => setSendOpen(true)}
        >
          {t('send_templates')}
        </Button>
        <Button
          viewStyle="text"
          className={styles.close}
          size="extraSmall"
          iconClassName={styles.closeIcon}
          icon={<ReactSVG src="/icons/close_thin.svg" wrapper="span" />}
          onClick={handleClose}
        />
        <FillRequestModal
          open={isSendOpen}
          onClose={setSendOpen}
          onSend={handleClose}
        />
      </div>
    </div>
  );
};

export default SendTemplatesNotification;
