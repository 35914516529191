import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import FillDocument from 'views/FillDocument';
import TrialEndModal from 'views/Guide/TrialEndModal';
import webView from 'helpers/webview';
import downloadFromUrl from 'helpers/downloadFromUrl';
import { TRIAL_ID } from 'data/constants';

import { ITemplateBlocksTree, ITemplateSide, ITemplateSideGroup } from 'interfaces/templates.interface';
import {
  useGetPackQuery,
  useRenderPackMutation
} from 'store/packs/packsApi';
import {
  ITemplateRenderData,
  useGetTemplatesListSidesQuery,
} from 'store/templates/templatesApi';
import { selectUserPermissions } from 'store/user/userSlice';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';

import styles from './PackFilling.module.css';

interface DownloadParams {
  sideValues: ITemplateRenderData['sideValues']
}

const getVariablePath = (id: string, folders: ITemplateSideGroup[]) => {
  const path = [];
  for (let i=0; i<folders.length; i++) {
    const tattrIndex = folders[i].tattrs.findIndex(tattr => id === tattr.id);
    if (tattrIndex >= 0) {
      path.push(i);
      path.push(tattrIndex);
      break;
    }
  }
  return path;
};

const Filling = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const userPermissions = useSelector(selectUserPermissions);
  const canChangeFolders = userPermissions?.includes('folders_templates');
  const [ isOpenEmpty, setOpenEmpty ] = useState(false);
  const [ sides, setSides ] = useState<ITemplateSide[]|undefined>();
  const [ blocksTree, setBlocksTree ] = useState<ITemplateBlocksTree|undefined>();
  const [ isShowModalDownload, setShowModalDownload ] = useState(false);
  const [ downloadParams, setDownloadParams ] = useState<DownloadParams|null>(null);

  const { data: pack, error: pageError } = useGetPackQuery(id ?? '');
  const { data: templatesSides, isSuccess: sidesSucess } =
    useGetTemplatesListSidesQuery({ ids: pack?.templates?.map(({ id }) => id) ?? [] }, { skip: !pack });
  const { data: currentTariff } = useGetCurrentTariffQuery();

  // const [ isOpenPaymentLock, setOpenPaymentLock ] = useState<boolean>(false);
  const [ isOpenTrialEnd, setOpenTrialEnd ] = useState<boolean>(false);

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (pack && templatesSides) {
      // массив для хранения уже добавленных уникальных id переменных без папок
      const sidesTattrsIds: { [key: string]: string[] } = pack.sides.reduce((acc, current, index) => ({ ...acc, [index+1+'']: [] }), {});

      const _sides: ITemplateSide[] = pack.sides.map(({ name }, index) => ({ id: index+1+'', name, sidesIds: [], tattrFolders: [], tables: [], carticles: [] }));
      if (sidesTattrsIds) pack.templates.forEach(({ id, sideIndices }) => {
        // сторона в документе - сторона в пакете
        sideIndices.forEach((packSideNumber, sideIndex) => {
          const side = templatesSides[id].sides[sideIndex];
          const sideNewData = _sides[packSideNumber-1];

          sideNewData.sidesIds?.push(side.id);

          if (side?.tattrFolders) {
            side.tattrFolders.forEach(({ id, name, tattrs }) => {
              if (!sideNewData.tattrFolders) return;
              let folderIndex = sideNewData.tattrFolders.findIndex(folder => id === folder.id);
              if (folderIndex === -1) {
                sideNewData.tattrFolders.push({ id, name, tattrs: [] });
                folderIndex = sideNewData.tattrFolders.length-1;
              }

              tattrs.forEach((tattr) => {
                // Собрать индексы для папки и переменной
                const tattrPath = getVariablePath(tattr.id, sideNewData.tattrFolders);

                if (tattrPath.length) { // если переменную добавили ранее
                  const prevAddedTattr = { ...sideNewData.tattrFolders[tattrPath[0]].tattrs[tattrPath[1]] };
                  // суммировать кол-во использований
                  prevAddedTattr.countBody = (prevAddedTattr.countBody ?? 0) + (tattr.countBody ?? 0);
                  prevAddedTattr.countVariative = (prevAddedTattr.countVariative ?? 0) + (tattr.countVariative ?? 0);
                  sideNewData.tattrFolders[tattrPath[0]].tattrs[tattrPath[1]] = prevAddedTattr;
                } else { // добавить новую
                  sidesTattrsIds[packSideNumber].push(tattr.id);
                  sideNewData.tattrFolders[folderIndex].tattrs.push(tattr);
                }
              });
            });
          }
          if (side?.tables) {
            sideNewData.tables = [ ...sideNewData.tables, ...side.tables ];
          }
          if (side?.carticles) {
            sideNewData.carticles = [ ...sideNewData.carticles, ...side.carticles ];
          }
        });
      });
      setSides(_sides);


      const _blocksTree = Object.values(templatesSides).every(({ blocksTree }) => !!blocksTree) ? (
        Object.values(templatesSides).reduce((acc: ITemplateBlocksTree, { blocksTree }) => (
          blocksTree ? {
            carticles: [ ...acc.carticles, ...blocksTree.carticles ],
            tables: [ ...acc.tables, ...blocksTree.tables ],
            tattrs: [ ...acc.tattrs, ...blocksTree.tattrs ]
          } : acc
        ), { carticles: [], tables: [], tattrs: [] })
      ) : undefined;
      setBlocksTree(_blocksTree);
    }
  }, [pack, templatesSides]);

  useEffect(() => {
    if (sidesSucess && templatesSides && Object.values(templatesSides).findIndex(({ sides }) => !!sides.length) === -1) setOpenEmpty(true);
    // eslint-disable-next-line
  }, [sidesSucess]);

  const [ renderPack, { isLoading: isDownloadLoading } ] = useRenderPackMutation();

  const handleCheckTrial = () => {
    if (!currentTariff) return false;
    const { tariff, isActive } = currentTariff;
    const isTrial = tariff.id === TRIAL_ID;

    if (isTrial && !isActive) {
      setOpenTrialEnd(true);
      return false;
    }
    // if (isTrial || !isPaid || !isActive) {
    //   setOpenPaymentLock(true);
    //   return false;
    // }
    return true;
  };

  const downloadTemplate = (format: 'pdf'|'zip'|'docx') => {
    if (!pack || !downloadParams) return;

    if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
      setOpenTrialEnd(true);
      return;
    }

    const data = {
      id: pack.id,
      name: pack.name,
      format,
      sideValues: downloadParams.sideValues
    };

    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.renderPack(pack.id, `${pack.name}.${format}`, { name: pack.name, format, sideValues: downloadParams.sideValues });
    } else {
      renderPack(data)
        .unwrap()
        .then(url => downloadFromUrl(url, `${pack.name}.${format}`));
    }
  };

  const handleClickDownload = (
    name: string,
    sideValues: ITemplateRenderData['sideValues']
  ) => {
    setDownloadParams({ sideValues });
    setShowModalDownload(true);
  };

  return (
    <AdminLayout
      title={t('fill_pack_by_template')}
      breadcrumbs={{ items: [ { name: t('templates_packs'), to: '/templates-packs', as: Link }, { name: t('fill_by_template') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('fill_pack_by_template')}</PageTitle>
      <FillDocument
        name={pack?.name}
        sides={sides}
        blocksTree={blocksTree}
        onClickDownload={handleClickDownload}
        isDownloadLoading={isDownloadLoading}
        checkAllowSelect={handleCheckTrial}
      />
      <TrialEndModal
        open={isOpenTrialEnd}
        onClose={setOpenTrialEnd}
        title={t('trial_period_end')}
        description={t('trial_end_render_description')}
        image={<img src="/images/trial-end.svg" alt="" />}
      />
      {/* <TrialEndModal
        open={isOpenPaymentLock}
        onClose={setOpenPaymentLock}
        title={t('sign_only_for_paid')}
        description={t('sign_only_for_paid_description')}
        image={<img src="/images/trial-lock.svg" alt="" />}
      /> */}
      <Modal
        size="small"
        open={isOpenEmpty}
        onClose={() => setOpenEmpty(false)}
        title={t('empty_document_sides')}
        buttons={
          canChangeFolders ? [
            {
              viewStyle: 'primary',
              children: t('open_document_in_editor'),
              onClick: () => navigate(`/templates-pack/${id}`)
            }
          ] : [
            {
              viewStyle: 'primary',
              children: t('return_to_templates'),
              onClick: () => navigate('/templates')
            }
          ]
        }
      >
        <ReactSVG src="/images/empty-doc-animation.svg" />
        <p className={styles.emptyDescription}>
          <Trans i18nKey="empty_document_sides_description" />
        </p>
      </Modal>
      <Modal
        size="medium"
        open={isShowModalDownload}
        onClose={() => setShowModalDownload(false)}
        buttons={[
          {
            children: t('no_every_doc_separate'),
            icon: <ReactSVG src="/icons/document-many.svg" wrapper="span" />,
            viewStyle: 'tertiary',
            onClick: () => downloadTemplate('zip')
          },
          {
            children: t('yes_single_pdf'),
            icon: <ReactSVG src="/icons/document-one.svg" wrapper="span" />,
            viewStyle: 'tertiary',
            onClick: () => downloadTemplate('pdf')
          },
          {
            children: t('yes_single_word'),
            icon: <ReactSVG src="/icons/document-one.svg" wrapper="span" />,
            viewStyle: 'tertiary',
            onClick: () => downloadTemplate('docx')
          },
        ]}
      >
        <p className={styles.downloadDescription}>{t('generate_documents_one_pdf')}</p>
      </Modal>
    </AdminLayout>
  );
};

export default Filling;