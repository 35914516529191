const fileToBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result as string;
      resolve(base64data.replace(/^data:image\/[a-z]+;base64,/, ''));
    };
    reader.onerror = reject;
  });
};

export default fileToBase64;
