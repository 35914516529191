import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, LoadingButton, Modal, nanoid, ThemeContext } from '@forma/forma-ui-kit';
import FillDocument from 'views/FillDocument';
import SendExternalFillModal from 'views/ExternalFill/SendExternalFillModal';
import TrialEndModal from 'views/Guide/TrialEndModal';
import webView from 'helpers/webview';
import copyToClipboard from 'helpers/copyToClipboard';
import downloadFromUrl from 'helpers/downloadFromUrl';
// import { TRIAL_ID } from 'data/constants';

import {
  ITemplateRenderData,
  useGetTemplateSidesQuery,
  // useRenderTemplateMutation
} from 'store/templates/templatesApi';
import {
  // useDuplicateExternalFillMutation,
  useGetExternalFillByIdQuery,
  // useLazyDownloadExternalFillAttachmentsQuery,
  useLazyDownloadRenderedExternalFillQuery,
  useSendExternalFillMutation
} from 'store/externalfill/externalfillApi';
// import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { ISidesListEditData, ITemplateSide, TFormatDocument } from 'interfaces/templates.interface';

import styles from './ModalChangeDocument.module.css';

interface SignatureModalProps {
  open: boolean,
  onClose?: () => void,
  id: string,
  name: string
}

const landingUrl = process.env.REACT_APP_LANDING_URL;

const InfoItem: FC<{ className?: string, label: string, value?: ReactNode }> = ({ className, label, value }) => {
  return (
    <div className={classNames(styles.infoItem, className)}>
      <div className={styles.infoLabel}>{label}:</div>
      <div className={styles.infoValue}>{value}</div>
    </div>
  );
};

const SignatureModalSkelet = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <InfoItem label={t('created')} value={<div className={classNames(styles.infoValueLoading, 'skeleton-loader')} />} />
        <InfoItem label={t('opened')} value={<div className={classNames(styles.infoValueLoading, 'skeleton-loader')} />} />
        <InfoItem label={t('filled_and_sended')} value={<div className={classNames(styles.infoValueLoading, 'skeleton-loader')} />} />
      </div>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          icon={<ReactSVG src="/icons/download.svg" />}
          disabled
        >
          {t('download_pdf')}
        </LoadingButton>
        {/* <LoadingButton
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          icon={<ReactSVG src="/icons/download.svg" />}
          disabled
        >
          {t('download_docx')}
        </LoadingButton> */}
        <LoadingButton
          viewStyle="tertiary"
          size="small"
          className={styles.button}
          icon={<ReactSVG src="/icons/download-file.svg" />}
          disabled
        >
          {t('download_attachments')}
        </LoadingButton>
      </ButtonsContainer>
    </div>
  );
};

const SignatureModal: FC<SignatureModalProps> = ({
  open, onClose, id, name
}) => {
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: externalFill } = useGetExternalFillByIdQuery(id, { skip: !open });
  const { data: templateSides } = useGetTemplateSidesQuery(externalFill?.templateId ?? '', { skip: !externalFill?.templateId || !open });
  // const { data: currentTariff } = useGetCurrentTariffQuery();
  // const [ duplicateExternalFill ] = useDuplicateExternalFillMutation();
  // const [ downloadExternalFillAttachments, { isLoading: isAttachmentsLoading } ] = useLazyDownloadExternalFillAttachmentsQuery();
  const [ downloadExternalFill, { isLoading: isDowloadLoading } ] = useLazyDownloadRenderedExternalFillQuery();
  // const [ renderTemplate, { isLoading: isRenderLoading } ] = useRenderTemplateMutation();
  const [ sendExternalFill ] = useSendExternalFillMutation();

  const [ isShowSend, setShowSend ] = useState<boolean>(false);
  const [ isFillingValid, setIsFillingValid ] = useState<boolean>(false);
  const [ sidesData, setSidesData ] = useState<ITemplateRenderData['sideValues']|undefined>(externalFill?.sideValues);
  const [ isChangedData, setChangedData ] = useState<boolean>(false);

  const [ isOpenTrialEnd, setOpenTrialEnd ] = useState<boolean>(false);

  useEffect(() => {
    webView.sendMessage(open ? 'documentIsOpen' : 'documentClosed');
  }, [open]);

  const handleDownloadDocument = (
    name: string,
    format: TFormatDocument = 'pdf',
  ) => {
    if (!externalFill) return null;

    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.downloadExternalFill(externalFill.urlKey, `${name}.${format}`);
    } else {
      downloadExternalFill(externalFill.urlKey)
        .unwrap()
        .then(url => {
          downloadFromUrl(url, `${name}.${format}`);
          if (onClose) onClose();
        });
    }
  };

  // const handleRenderTemplate = (
  //   name: string,
  //   sideValues: ITemplateRenderData['sideValues'],
  //   format: TFormatDocument = 'pdf',
  // ) => {
  //   if (!externalFill) return null;

  //   if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
  //     setOpenTrialEnd(true);
  //     return;
  //   }

  //   if (window?.navigator?.userAgent === 'forma-mobile') {
  //     webView.renderTemplate(externalFill.templateId, `${name}.${format}`, { name, format, sideValues });
  //   } else {
  //     renderTemplate({ id: externalFill.templateId, name, format, sideValues })
  //       .unwrap()
  //       .then(url => {
  //         downloadFromUrl(url, `${name}.${format}`);
  //         if (onClose) onClose();
  //       });
  //   }
  // };

  const handleClickDownload = (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument = 'pdf',
    isChanged: boolean
  ) => {
    // if (isChanged) handleRenderTemplate(name, sideValues, format);
    // else handleDownloadDocument(name, format);
    if (isChanged) return;
    handleDownloadDocument(name, format);
  };

  // const handleClickDuplicate = () => {
  //   const res = await duplicateExternalFill({ id });
  //   if ('data' in res) {
  //     dispatch(addNotification({ content: t('external_fill_created'), type: 'SUCCESS' }));
  //     localStorage.setItem('externalFillSendingId', res.data.id);
  //     navigate('/documents');
  //     if (onClose) onClose();
  //   }
  // };

  // const handleClickDownloadAttachments = () => {
  //   if (!externalFill) return null;
  //   if (window?.navigator?.userAgent === 'forma-mobile') {
  //     webView.externalFillAttachemnts(id, `${externalFill.name}.zip`);
  //     if (onClose) onClose();
  //   } else {
  //     downloadExternalFillAttachments(id)
  //       .unwrap()
  //       .then(url => {
  //         downloadFromUrl(url, `${externalFill.name}.zip`);
  //         if (onClose) onClose();
  //       });
  //   }
  // };

  const handleClickSend = async (id: string, data: { login: string }) => {
    setShowSend(false);
    const res = await sendExternalFill({ id, email: data.login });
    if ('data' in res) {
      dispatch(addNotification({ content: t('email_sended_to_your_counteragent'), type: 'SUCCESS' }));
    }
  };

  const handleCopyLink = () => {
    if (!externalFill) return null;
    const res = copyToClipboard(`${landingUrl}/${lang}/sign/${externalFill.urlKey}`);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
    webView.sendMessage(`openShare ${landingUrl}/${lang}/sign/${externalFill.urlKey}`);
  };

  const { sides, valueSides } = useMemo(() => {
    if (!externalFill || !templateSides) return { sides: undefined, valueSides: undefined };

    const sides: ITemplateSide[] = [];
    const valueSides: ISidesListEditData = {};
    for (let i=0; i < templateSides.sides.length; i++) {
      const side = templateSides.sides[i];
      const id = side.id ?? i+1+'';
      sides.push({ ...side, id });

      const sideValues = externalFill.sideValues?.[i] ?? {};
      valueSides[id] = {
        ...sideValues,
        id,
        tableValues: sideValues.tableValues?.length > 0 ? (
          sideValues.tableValues.map(({ products, ...table }) => ({
            ...table,
            products: products.map(product => ({ id: nanoid(8), ...product })),
            customProductsIds: []
          }))
        ) : [],
        tattrValues: sideValues.tattrValues ?? {},
        tattrErrors: {},
        // isValid: true,
      };
    }

    return { sides, valueSides };
  }, [externalFill, templateSides]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={name}
      width="100%"
      maxWidth="1150px"
    >
      {(externalFill && templateSides) ? (
        <div className={classNames(styles.root, styles.sign)}>
          <div className={styles.info}>
            <InfoItem label={t('created')} value={format(new Date(externalFill.createdAt), 'dd.MM.yyyy HH:mm')} />
            <InfoItem label={t('opened')} value={externalFill.readAt && format(new Date(externalFill.readAt), 'dd.MM.yyyy HH:mm')} />
            <InfoItem label={t('filled_and_sended')} value={externalFill.filledAt && format(new Date(externalFill.filledAt), 'dd.MM.yyyy HH:mm')} />
            {externalFill.signature?.status === 'REJECTED' && (
              <InfoItem className={styles.fullWidth} label={t('decline_reason')} value={externalFill.signature.signComment ?? '-'} />
            )}
          </div>
          <ButtonsContainer className={styles.buttons}>
            <LoadingButton
              type="submit"
              className={styles.button}
              viewStyle="tertiary"
              size="small"
              onClick={() => (externalFill && sidesData) && handleClickDownload(externalFill.name, sidesData, 'pdf', isChangedData)}
              disabled={!isFillingValid || externalFill.signature?.status !== 'SIGNED'}
              isLoading={isDowloadLoading}
              icon={<ReactSVG src="/icons/download.svg" />}
            >
              {t('download_pdf')}
            </LoadingButton>
            {/* <LoadingButton
              type="submit"
              className={styles.button}
              viewStyle="tertiary"
              size="small"
              onClick={() => (externalFill && sidesData) && handleClickDownload(externalFill.name, sidesData, 'docx', isChangedData)}
              disabled={!isFillingValid}
              isLoading={isRenderLoading || isDowloadLoading}
              icon={<ReactSVG src="/icons/download.svg" />}
            >
              {t('download_docx')}
            </LoadingButton> */}
            {/* <LoadingButton
              viewStyle="tertiary"
              size="small"
              className={styles.button}
              icon={<ReactSVG src="/icons/download-file.svg" />}
              onClick={handleClickDownloadAttachments}
              disabled={!externalFill?.attachments.length || !externalFill.attachments.every(({ size }) => !!size)}
              isLoading={isAttachmentsLoading}
            >
              {t('download_attachments')}
            </LoadingButton> */}
            {/* <LoadingButton
              viewStyle="tertiary"
              size="small"
              className={styles.button}
              icon={<ReactSVG src="/icons/copy.svg" />}
              onClick={handleClickDuplicate}
              disabled={!!externalFill?.isReady || externalFill.signature?.status !== 'TO_SIGN'}
            >
              {t('duplicate_to_sign')}
            </LoadingButton> */}
            <LoadingButton
              viewStyle="primary"
              size="small"
              className={styles.button}
              onClick={handleCopyLink}
              disabled={!!externalFill?.isReady || externalFill.signature?.status !== 'TO_SIGN'}
            >
              {t('copy_link')}
            </LoadingButton>
          </ButtonsContainer>

          {sides && (
            <FillDocument
              className={styles.filling}
              name={name}
              sides={sides}
              onClickDownload={handleClickDownload}
              isDownloadLoading={isDowloadLoading}
              valueSides={valueSides}
              showFillMessage={false}
              setIsFillingValid={setIsFillingValid}
              setSidesDataForInnerRenderButton={setSidesData}
              onSetChanged={() => setChangedData(true)}
              showButtons={false}
              showHead={false}
            />
          )}
        </div>
      ) : <SignatureModalSkelet />}

      {externalFill && (
        <SendExternalFillModal
          open={isShowSend}
          onClose={() => setShowSend(false)}
          onSubmit={data => handleClickSend(id, data)}
          onClickCopyLink={handleCopyLink}
        />
      )}

      <TrialEndModal
        open={isOpenTrialEnd}
        onClose={setOpenTrialEnd}
        title={t('trial_period_end')}
        description={t('trial_end_render_description')}
        image={<img src="/images/trial-end.svg" alt="" />}
      />
    </Modal>
  );
};

export default SignatureModal;
