import { FC, useState, useRef, useEffect } from 'react';
import update from 'immutability-helper';
import { nanoid } from '@reduxjs/toolkit';
import { DndContext, DragOverlay, DragStartEvent, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, Modal, useDebounce } from '@forma/forma-ui-kit';
import SideBlock from '../SideBlock';

import { IUserVariableItem, IVariableItem, IVariablesHierarhyItem } from 'interfaces/variables.interface';
import { ITemplateSide, ITemplateSideGroup } from 'interfaces/templates.interface';

import styles from './side.module.css';

interface SideProps {
  sides: ITemplateSide[],
  variablesGroups?: { [key: string]: IVariablesHierarhyItem },
  availableVariables?: { [key: string]: IVariableItem },
  userVariables?: { [key: string]: IUserVariableItem },
  onChange: (sides: ITemplateSide[]) => void,
  onAddVariable: (sideId: string, sideName: string, tattrId: string, tattrName: string, tattrType: string) => void,
  onChangeVariable: (tattrId: string, tattrName: string, tattrType: string) => void,
  onClickAddVariations: (sideId: string) => void,
  onClickAddTable: (sideId: string) => void,
  fullScreen?: boolean,
  fixed?: boolean,
}

const Side: FC<SideProps> = ({
  sides, variablesGroups, availableVariables, userVariables, onClickAddVariations, onClickAddTable,
  onChange, onAddVariable, onChangeVariable, fullScreen, fixed
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [ isOpen, setOpen ] = useState<boolean>(false);
  const [ dragIndex, setDragIndex ] = useState<number|null>(null);

  const updateSideTop = () => {
    if (!rootRef.current || !rootRef.current?.parentElement) return;
    const parentRectTop = rootRef.current?.parentElement.getBoundingClientRect().top;
    rootRef.current.style.top = parentRectTop + 'px';
  };

  const updateControlsHeight = () => {
    if (!containerRef?.current) return;
    const containerTop = containerRef.current.getBoundingClientRect().top;
    if (containerTop < 0) return;
    containerRef.current.style.height = window.innerHeight - containerTop + 'px';
  };

  useEffect(() => {
    updateControlsHeight();
    updateSideTop();
    document.addEventListener('scroll', updateControlsHeight);
    document.addEventListener('scroll', updateSideTop);
    return () => {
      document.removeEventListener('scroll', updateControlsHeight);
      document.removeEventListener('scroll', updateSideTop);
    };
    // eslint-disable-next-line
  }, [rootRef]);

  const changeTemplateSideName = useDebounce((sideId: string, sideName: string) => {
    window.editor?.execute('changeVariablesSide', { sideId, sideName });
    window.editor?.execute('changeProductsTablesSide', { sideId, sideName });
  }, 300);

  const handleDragStart = ({ active }: DragStartEvent) => {
    setDragIndex(active.data.current?.index);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setDragIndex(null);
    if (over && active.id !== over.id) {
      const oldIndex = active.data.current?.index;
      const newIndex = over.data.current?.index;

      onChange(
        update(sides, {
          $splice: [
            [oldIndex, 1],
            [newIndex, 0, sides[oldIndex]]
          ]
        })
      );
    }
  };

  const handleAddSide = () => {
    onChange([ ...sides, { id: nanoid(8), name: `${t('side')} ${sides.length+1}`, tattrFolders: [], tables: [], carticles: [] }]);
  };

  const handleChangeSide = (data: { id: string, name: string, tattrFolders: ITemplateSideGroup[] }) => {
    const sideIndex = sides.findIndex(({ id }) => data.id === id);
    onChange(
      update(sides, {
        [sideIndex]: {
          $merge: data
        }
      })
    );
    changeTemplateSideName(data.id, data.name);
  };

  const handleRemoveSide = (sideId: string) => {
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    onChange(
      update(sides, {
        $splice: [[ sideIndex, 1 ]]
      })
    );
    window.editor?.execute('removeVariables', { sideId });
    window.editor?.execute('removeVariations', { sideId });
    window.editor?.execute('removeProductsTables', { sideId });
  };

  return (
    <div className={classNames(styles.root, fixed && styles.fixed, isOpen && styles.open)} ref={rootRef}>
      {fixed && (
        <div className={styles.opener} onClick={() => setOpen(!isOpen)}>
          <ReactSVG className={styles.openerIcon} src="/icons/arrow-left-thin.svg" />
        </div>
      )}
      <div className={classNames(styles.controls, 'styled-scrollbar')} ref={containerRef}>
        {!fullScreen && (
          <Modal
            size="semiLarge"
            title={t('edit_sides')}
            control={
              <Button
                viewStyle="textLight"
                className={styles.fullScreenButton}
                icon={<ReactSVG src="/icons/fullscreen.svg" wrapper="span" />}
              >
                {t('open_fullscreen')}
              </Button>
            }
            buttons={[
              { children: t('close') }
            ]}
          >
            <Side
              sides={sides}
              variablesGroups={variablesGroups}
              availableVariables={availableVariables}
              userVariables={userVariables}
              onChange={onChange}
              onAddVariable={onAddVariable}
              onChangeVariable={onChangeVariable}
              onClickAddVariations={onClickAddVariations}
              onClickAddTable={onClickAddTable}
              fullScreen
            />
          </Modal>
        )}
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <SortableContext items={sides} strategy={verticalListSortingStrategy}>
            {sides.map(({ id, name, tattrFolders, tables, carticles }, index) => (
              <SideBlock
                id={id}
                index={index}
                name={name}
                folders={tattrFolders}
                tables={tables}
                carticles={carticles}
                opacity={dragIndex === index ? 0 : 1}
                variablesGroups={variablesGroups}
                availableVariables={availableVariables}
                userVariables={userVariables}
                onAddVariable={onAddVariable}
                onChangeVariable={onChangeVariable}
                onClickAddVariations={onClickAddVariations}
                onClickAddTable={onClickAddTable}
                onRemove={handleRemoveSide}
                onChange={handleChangeSide}
                fullScreen={fullScreen}
                key={id}
              />
            ))}
          </SortableContext>
          {(dragIndex || dragIndex === 0) && (
            <DragOverlay zIndex={10} modifiers={[ restrictToFirstScrollableAncestor ]}>
              <SideBlock
                id={sides[dragIndex].id}
                index={dragIndex}
                name={sides[dragIndex].name}
                folders={sides[dragIndex].tattrFolders}
                availableVariables={availableVariables}
                tables={sides[dragIndex].tables}
                carticles={sides[dragIndex].carticles}
                onAddVariable={onAddVariable}
                onChangeVariable={onChangeVariable}
                onClickAddVariations={onClickAddVariations}
                onClickAddTable={onClickAddTable}
                onRemove={handleRemoveSide}
                onChange={handleChangeSide}
                fullScreen={fullScreen}
                isDragging
              />
            </DragOverlay>
          )}
        </DndContext>
        <div className={styles.controlsButtons}>
          <Button
            id="add_side_button"
            viewStyle={fullScreen ? 'tertiary' : 'secondary'}
            className={styles.buttonAdd}
            icon={<ReactSVG src="/icons/plus.svg" className={styles.iconAdd} />}
            onClick={handleAddSide}
            fullWidth
            shadow
          >
            {t('add_side')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Side;
