import { useDispatch } from 'react-redux';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ThemeContext } from '@forma/forma-ui-kit';
import copyToClipboard from 'helpers/copyToClipboard';
import webView from 'helpers/webview';

import { addNotification } from 'store/notifications/notificationsSlice';

import styles from './sended-sign-modal.module.css';

interface SendedSignModalProps {
  open: boolean,
  onClose?: () => void,
  urlKey: string
}

const landingUrl = process.env.REACT_APP_LANDING_URL;

const SendedSignModal: FC<SendedSignModalProps> = ({ open, onClose, urlKey }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);

  const handleCopyLink = () => {
    const res = copyToClipboard(`${landingUrl}/${lang}/sign/${urlKey}`);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
    webView.sendMessage(`openShare ${landingUrl}/${lang}/sign/${urlKey}`);
  };

  return (
    <Modal
      size="large"
      open={open}
      onClose={onClose}
      title={t('document_sended_to_sign')}
      buttons={[
        {
          className: styles.modalButton,
          viewStyle: 'tertiary',
          children: t('copy_link'),
          onClick: handleCopyLink
        },
        {
          className: styles.modalButton,
          viewStyle: 'primary',
          children: t('fine'),
        }
      ]}
    >
      <div className={styles.description}>{t('document_sended_to_sign_description')}</div>
      <div className={styles.imageWrap}>
        <img src="/images/sign-sended.svg" className={styles.image} alt="" />
      </div>
    </Modal>
  );
};

export default SendedSignModal;
