import { ITemplateBlocksTree, ITemplateSide } from 'interfaces/templates.interface';

const checkCarticleTattrInSide = (id: string, tattrId: string, sideId: string, sidesIds?: string[]): boolean => {
  if (id === tattrId) return true; // обратная совместимость (сначала переменные были без стороны)
  if (id === `${tattrId}.${sideId}`) return true;
  if (sidesIds?.find(sideId => (id === `${tattrId}.${sideId}`))) return true; // поиск в сторонах в пакете
  return false;
};

interface IShowingIds {
  carticles: string[],
  tattrs: string[],
  tables: string[],
}

const getTreeIdsToShow = (tree: ITemplateBlocksTree, selectedVariants?: string[]): IShowingIds => {
  const carticles: string[] = tree.carticles.map(({ id }) => id);
  const tattrs: string[] = [ ...tree.tattrs ];
  const tables: string[] = [ ...tree.tables ];

  if (selectedVariants?.length) {
    tree.carticles.forEach(({ variants }) => {
      variants.forEach(({ id: variantId, childs }) => {
        if (!selectedVariants.includes(variantId)) return;
        const data = getTreeIdsToShow(childs, selectedVariants);
        carticles.push(...data.carticles);
        tattrs.push(...data.tattrs);
        tables.push(...data.tables);
      });
    });
  }

  return {
    carticles,
    tattrs,
    tables
  };
};

const getLegacySidesIdsToShow = (sides: ITemplateSide[], selectedVariants?: string[]): IShowingIds => {
  const carticles: string[] = [];
  const tattrs: string[] = [];
  const tables: string[] = [];

  const tattrsInVariations: string[] = [];
  const tattrsInSelectedVariants: string[] = [];

  const tablesInVariations: string[] = [];
  const tablesInSelectedVariants: string[] = [];

  const carticlesInVariations: string[] = [];
  const carticlesInSelectedVariants: string[] = [];

  sides?.forEach(({ carticles }) => {
    carticles.forEach(({ id, variants, parents }) => {
      variants.forEach(({ id, tattrs, tables }) => {
        tattrsInVariations.push(...tattrs);
        if (selectedVariants?.includes(id)) tattrsInSelectedVariants.push(...tattrs);

        tablesInVariations.push(...tables);
        if (selectedVariants?.includes(id)) tablesInSelectedVariants.push(...tables);
      });

      if (parents?.length) {
        carticlesInVariations.push(id);
        parents.forEach(variantId => {
          if (selectedVariants?.includes(variantId)) carticlesInSelectedVariants.push(id);
        });
      }
    });
  });

  sides.forEach(side => {
    const _carticles = side.carticles.filter(({ id, countBody, countVariative }) => (
      // если вариации нет в вариациях или вариация в выбранной вариации
      !carticlesInVariations.includes(id)
      || carticlesInSelectedVariants.includes(id)
      || (countBody && countVariative && countBody > countVariative)
    )).map(({ id }) => id);
    carticles.push(..._carticles);

    const _tattrs = side.tattrFolders.flatMap(folder => (
      folder.tattrs.filter(({ id: tattrId, countBody, countVariative }) => {
        if (!tattrId) return false;
        // если вариации пусты или переменной нет в вариациях или переменная в выбранной вариации
        return !tattrsInVariations.length
          || !tattrsInVariations.find(tId => checkCarticleTattrInSide(tId, tattrId, side.id, side.sidesIds))
          || tattrsInSelectedVariants.find(tId => checkCarticleTattrInSide(tId, tattrId, side.id, side.sidesIds))
          // || !tattrsInVariations.find(tId => tId === tattrId || tId === `${tattrId}.${sideId}`)
          // || tattrsInSelectedVariants.find(tId => tId === tattrId || tId === `${tattrId}.${sideId}`)
          || (countBody && countVariative && countBody > countVariative);
      }).map(({ id }) => `${id}.${side.id}`)
    ));
    tattrs.push(..._tattrs);

    const _tables = side.tables.filter(({ id }) => (
      // если таблицы нет в вариациях или таблица в выбранной вариации
      !tablesInVariations.includes(id) || tablesInSelectedVariants.includes(id)
    )).map(({ id }) => id);
    tables.push(..._tables);
  });

  return {
    carticles,
    tattrs,
    tables
  };
};

export const getSidesIdsToShow = (sides: ITemplateSide[], blocksTree?: ITemplateBlocksTree, selectedVariants?: string[]): IShowingIds => {
  const carticles: string[] = [];
  const tattrs: string[] = [];
  const tables: string[] = [];

  if (blocksTree) {
    const ids = getTreeIdsToShow(blocksTree, selectedVariants);

    carticles.push(...ids.carticles);
    tattrs.push(...ids.tattrs);
    tables.push(...ids.tables);
  } else {
    const ids = getLegacySidesIdsToShow(sides, selectedVariants);

    carticles.push(...ids.carticles);
    tattrs.push(...ids.tattrs);
    tables.push(...ids.tables);
  }


  return {
    carticles: [ ...(new Set(carticles)) ],
    tattrs: [ ...(new Set(tattrs)) ],
    tables: [ ...(new Set(tables)) ]
  };
};

const mapSidesToFilling = (sides: ITemplateSide[], blocksTree?: ITemplateBlocksTree, selectedVariants?: string[]) => {
  const hasCarticles = !!sides?.find(({ carticles }) => !!carticles?.length);
  if (!hasCarticles) return sides;

  const idsToShow = getSidesIdsToShow(sides, blocksTree, selectedVariants);

  return sides.map(({ id: sideId, sidesIds, tattrFolders, tables, carticles, ...rest }) => {
    const _carticles = carticles.filter(({ id }) => idsToShow.carticles.includes(id));

    const _tattrFolders = tattrFolders.map(folder => ({
      ...folder,
      tattrs: folder.tattrs.filter(({ id: tattrId }) => (
        tattrId && idsToShow.tattrs.find(tId => checkCarticleTattrInSide(tId, tattrId, sideId, sidesIds))
      ))
    }));

    const _tables = tables.filter(({ id }) => idsToShow.tables.includes(id));

    return {
      id: sideId,
      tattrFolders: _tattrFolders,
      tables: _tables,
      carticles: _carticles,
      ...rest
    };
  });
};

export default mapSidesToFilling;
