import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Modal } from '@forma/forma-ui-kit';

import styles from './rename-modal.module.css';

interface RemoveModalProps {
  name?: string,
  control?: ReactElement,
  open?: boolean,
  onClose?: () => void,
  onSubmit: (name: string) => void,
  title: string,
  placeholder: string
}

const RenameModal: FC<RemoveModalProps> = ({
  name: defaultName, control, open, onClose, onSubmit, title, placeholder
}) => {
  const { t } = useTranslation();
  const [ name, setName ] = useState('');

  useEffect(() => {
    if (defaultName) setName(defaultName);
  }, [defaultName]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      control={control}
      size="small"
      title={title}
      buttons={[
        {
          children: t('save'),
          onClick: () => onSubmit(name)
        },
        {
          children: t('cancel'),
          viewStyle: 'tertiary'
        }
      ]}
    >
      <div className={styles.renameModal}>
        <Input
          id="name_edit_input"
          name="edit_name"
          type="text"
          viewStyle="secondary"
          placeholder={placeholder}
          onChange={e => setName(e.target.value)}
          maxLength={100}
          showClearButton
          value={name}
        />
      </div>
    </Modal>
  );
};

export default RenameModal;
