import { useTranslation } from 'react-i18next';
import Slider from 'components/Slider';

import styles from './account-banner.module.css';

const RegisterBanner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Slider autoPlay={5000} loop>
        <img src="/images/account_banner_1.svg" className={styles.image} alt="" />
        <img src="/images/account_banner_2.svg" className={styles.image} alt="" />
        <img src="/images/account_banner_3.svg" className={styles.image} alt="" />
      </Slider>
      <div className={styles.description}>{t('account.with_forma_created_many_docs')}</div>
    </div>
  );
};

export default RegisterBanner;
