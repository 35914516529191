import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonsContainer, Modal } from '@forma/forma-ui-kit';

import styles from './trial-end-modal.module.css';
import { Link } from 'react-router-dom';

interface TrialEndModalProps {
  open: boolean,
  onClose: (open: false) => void,
  title: ReactNode,
  description: ReactNode,
  image?: ReactNode
}

const TrialEndModal: FC<TrialEndModalProps> = ({ open, onClose, title, description, image }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      maxWidth="721px"
      width="100%"
    >
      <div className={styles.content}>
        <div className={styles.description}>{description}</div>
        {image && (
          <div className={styles.image}>
            {image}
          </div>
        )}
        <ButtonsContainer className={styles.buttons}>
          <Button as={Link} className={styles.button} to="/select-tariff" fullWidth>
            {t('go_to_tariffs')}
          </Button>
        </ButtonsContainer>
      </div>
    </Modal>
  );
};

export default TrialEndModal;
